import gql from 'graphql-tag';
import {} from '../type';
import { PackageType } from '../type/package.type';

export interface GetMyPackagesByTravelQueryVariables {
  id: string;
}
export interface GetMyPackagesByTravelQueryData {
  getPackagesByTravel: PackageType[];
}
export interface GetPackageQueryVariables {
  id: string;
}
export interface GetPackageQueryData {
  getPackage: PackageType;
}

export const GET_MY_PACKAGES_BY_TRAVEL_QUERY = gql`
  query GetMyPackagesByTravel($id: String!) {
    getPackagesByTravel(id: $id) {
      id
      reference
      senderFullname
      senderPhone
      receiverFullname
      receiverPhone
      weight
      travelId
      containerId
      containerName
      status
      paid
    }
  }
`;
export const GET_PACKAGE_QUERY = gql`
  query GetPackage($id: String!) {
    getPackage(id: $id) {
      id
      reference
      senderFullname
      senderPhone
      receiverFullname
      receiverPhone
      weight
      travelId
      status
      containerId
      containerName
      paid
    }
  }
`;
