import './password.css';
import React, { useEffect, useState } from 'react';
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { useMutation } from '@apollo/client';
import {
  ChangeMyPasswordMutationData,
  CHANGE_MY_PASSWORD_MUTATION,
  ChangeMyPasswordMutationVariables,
} from '../../gql';
import { Menu } from '../../components/menu';
interface PasswordPageProps {}
export const PasswordPage: React.FC<PasswordPageProps> = (
  props: PasswordPageProps,
) => {
  const [changeMyPassword] = useMutation<
    ChangeMyPasswordMutationData,
    ChangeMyPasswordMutationVariables
  >(CHANGE_MY_PASSWORD_MUTATION);
  const [load, setLoading] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');

  const handlePasswordChange = () => {
    setLoading(true);
    setToastMessage('');
    console.log('olds', oldPassword, newPassword, repeatNewPassword);
    if (!oldPassword || !newPassword || !repeatNewPassword) {
      setLoading(false);
      setToastType('danger');
      setToastMessage('Tous les champs sont obligatoires');
    } else if (newPassword.length < 5) {
      setLoading(false);
      setToastType('danger');
      setToastMessage('Le nouveau mot de passe doit avoir au min 5 caractères');
    } else if (newPassword !== repeatNewPassword) {
      setLoading(false);
      setToastType('danger');
      setToastMessage(`Le nouveau mot de passe n'est identifique `);
    } else {
      changeMyPassword({ variables: { oldPassword, newPassword } }).then(
        (resp) => {
          setLoading(false);
          if (resp?.data?.changeMyPassword) {
            setToastType('success');
            setToastMessage(`Le mot de passe est mis à jour avec succès!`);
          }
        },
        (error: Error) => {
          setLoading(false);
          setToastType('danger');
          setToastMessage(error.message);
        },
      );
    }
  };
  return (
    <IonPage id='mainContent'>
      <IonLoading isOpen={load} backdropDismiss={false} message='Patientez' />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonMenuButton
              slot='start'
              color='secondary'
              className='Ion_Menu_Icon'
            />
          </IonButtons>
          <IonTitle className='ion-text-center'>Mot de passe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className='PasswordPage_Grid'>
          <IonRow className='PasswordPage_Row'>
            <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
            <IonCol sizeXs='12' sizeSm='10' sizeMd='8'>
              <IonGrid>
                <IonRow>
                  <IonCol size='12'>
                    <IonLabel className='PasswordPage_IonLabel'>
                      Changement de mot de passe
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='12'>
                    <Input
                      label='Mot de passe actuel'
                      type='password'
                      value={oldPassword}
                      handleChange={(value) => {
                        setOldPassword(value ? value : '');
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='12'>
                    <Input
                      label='Nouveau mot de passe'
                      type='password'
                      value={newPassword}
                      handleChange={(value) => {
                        setNewPassword(value ? value : '');
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='12'>
                    <Input
                      label='Confirmer le mot de passe'
                      value={repeatNewPassword}
                      type='password'
                      handleChange={(value) => {
                        setRepeatNewPassword(value ? value : '');
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='12' className='ion-text-center'>
                    <Button
                      text='Changer'
                      handleClick={() => {
                        handlePasswordChange();
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
