import './index.css';
import React, { useMemo, useState } from 'react';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonModal,
  IonButtons,
  IonButton,
  IonSearchbar,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonLoading,
  IonToast,
} from '@ionic/react';
import { close, searchCircle, time, timeOutline } from 'ionicons/icons';
import { COUNTRIES } from '../../data/countries';
import { useLazyQuery } from '@apollo/client';
import {
  LOCATION_QUERY,
  LocationQueryData,
  LocationQueryVariables,
  SEARCH_QUERY,
  SearchQueryData,
  SearchQueryVariables,
} from '../../gql/query/search-query.gql';
import { PlaceType } from '../../gql/type/place.type';
interface PointSelectorModalProps {
  isOpen: boolean;
  handleClose: (success?: boolean) => void;
  handleSelected: (value: string, coordinates: number[]) => void;
  message: string;
}
export const PointSelectorModal: React.FC<PointSelectorModalProps> = (
  props: PointSelectorModalProps,
) => {
  const SEARCH_HISTORICS_KEY = 'search_historics';
  const { handleClose, isOpen, message, handleSelected } = props;
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const [loading, setLoading] = useState<boolean>(false);
  const [word, setWord] = useState<string>('');
  const [places, setPlaces] = useState<PlaceType[]>([]);
  const [searchHistorics, setSearchHistorics] = useState<PlaceType[]>([]);
  const [searchQuery] = useLazyQuery<SearchQueryData, SearchQueryVariables>(
    SEARCH_QUERY,
  );
  const [locationQuery] = useLazyQuery<
    LocationQueryData,
    LocationQueryVariables
  >(LOCATION_QUERY);
  const getLocation = (value: string, place_id: string) => {
    setLoading(true);
    locationQuery({ variables: { place_id }, fetchPolicy: 'cache-first' })
      .then((res) => {
        setLoading(false);
        if (
          res &&
          res.data &&
          res.data.getLocation &&
          res.data.getLocation.coordinates &&
          res.data.getLocation.coordinates.length > 0
        ) {
          handleSelected(value, res.data.getLocation.coordinates);
        } else {
          setToastMessage('Une erreur est survenue, merci de reessayer.');
          setToastType('danger');
        }
      })
      .catch((error) => {
        setToastMessage('Une erreur est survenue, merci de reessayer.');
        setToastType('danger');
      });
  };
  const getPlacesFromLocalStorage = () => {
    const res = localStorage.getItem(SEARCH_HISTORICS_KEY);
    const places: PlaceType[] = JSON.parse(res || '[]');
    return places;
  };
  const getHistoricsPlaces = () => {
    const places = getPlacesFromLocalStorage();
    setSearchHistorics(places.reverse());
  };
  const searchText = () => {
    searchQuery({ variables: { text: word } })
      .then((res) => {
        if (res.data && res.data.search && res.data.search.length > 0) {
          setPlaces(res.data.search);
          setSearchHistorics([]);
        } else {
          getHistoricsPlaces();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useMemo(() => {
    if (word) {
      searchText();
    } else {
      getHistoricsPlaces();
    }
  }, [word]);

  const addItemToSearchHistorics = (place: PlaceType) => {
    const places = getPlacesFromLocalStorage();
    if (places) {
      if (places.length === 5) {
        places.shift();
      }
      const found = places.find((p) => place.place_id === p.place_id);
      if (!found) {
        places.push(place);
      }
      localStorage.setItem(SEARCH_HISTORICS_KEY, JSON.stringify(places));
    }
  };
  return (
    <>
      <IonLoading
        isOpen={loading}
        backdropDismiss={false}
        message='Patientez'
      />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          handleClose();
        }}
      >
        <IonHeader>
          <IonToolbar color='primary'>
            <IonTitle className='ion-text-center' style={{ paddingLeft: 50 }}>
              {message}
            </IonTitle>
            <IonButtons slot='end' color='secondary'>
              <IonButton onClick={() => handleClose()}>
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              className='Point__Selector__Modal__searchbar'
              searchIcon={searchCircle}
              placeholder='Recherche un pays'
              onInput={(e) => {
                setWord(e.currentTarget.value || '');
              }}
              onIonClear={() => setWord('')}
            ></IonSearchbar>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {searchHistorics.map((place) => (
              <IonItem
                key={place.place_id}
                button={true}
                detail={false}
                onClick={() => {
                  setWord('');
                  getLocation(place.description, place.place_id);
                }}
              >
                <IonIcon icon={timeOutline} style={{ marginRight: '10px' }} />
                <IonLabel>{place.description}</IonLabel>
              </IonItem>
            ))}
            {places.map((place) => (
              <IonItem
                key={place.place_id}
                button={true}
                detail={false}
                onClick={() => {
                  setWord('');
                  addItemToSearchHistorics(place);
                  getLocation(place.description, place.place_id);
                  setPlaces([]);
                }}
              >
                <IonLabel>{place.description}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  );
};
