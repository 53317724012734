import gql from 'graphql-tag';
import {} from '../type';
import { PlaceType } from '../type/place.type';
import { LocationType } from '../type/location.type';

export interface SearchQueryVariables {
  text: string;
}
export interface SearchQueryData {
  search: PlaceType[];
}

export const SEARCH_QUERY = gql`
  query Search($text: String!) {
    search(text: $text) {
      place_id
      description
    }
  }
`;
export interface LocationQueryVariables {
  place_id: string;
}
export interface LocationQueryData {
  getLocation: LocationType;
}

export const LOCATION_QUERY = gql`
  query GetLocation($place_id: String!) {
    getLocation(place_id: $place_id) {
      place_id
      coordinates
    }
  }
`;
