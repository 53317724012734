import gql from 'graphql-tag';
import { ContainerInput } from '../input/container.input';
export interface ContainerMutationVariables {
  input: ContainerInput;
}
export interface UpdateContainerMutationVariables {
  input: ContainerInput;
  id: string;
}

export interface DeleteContainerMutationVariables {
  id: string;
}
export interface CreateContainerMutationData {
  createContainer: void;
}
export interface UpdateContainerMutationData {
  updateContainer: void;
}

export interface DeleteContainerMutationData {
  deleteContainer: void;
}
export const CREATE_CONTAINER_MUTATION = gql`
  mutation CreateContainer($input: ContainerInput!) {
    createContainer(input: $input)
  }
`;
export const UPDATE_CONTAINER_MUTATION = gql`
  mutation UpdateContainer($input: ContainerInput!, $id: String!) {
    updateContainer(input: $input, id: $id)
  }
`;
export const DELETE_CONTAINER_MUTATION = gql`
  mutation DeleteContainer($id: String!) {
    deleteContainer(id: $id)
  }
`;
