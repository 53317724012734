import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { BACKEND_BASE_URL, BACKEND_WS_URL, TOKEN_KEY } from '../constant';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      if (extensions && extensions['code'] === 'UNAUTHENTICATED') {
        window.location.href = '/login';
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const customFetch = (uri: any, options: any) => {
  return fetch(uri, options).then((response) => {
    if (response.status >= 500) {
      // or handle 400 errors
      return Promise.reject(response.status);
    }
    return response;
  });
};
const httpLink = createHttpLink({
  uri: `${BACKEND_BASE_URL}/graphql`,
  fetch: customFetch,
});
const wsLink = new WebSocketLink({
  uri: `${BACKEND_WS_URL}/graphql`,
  options: {
    reconnect: true,
  },
});
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, link]),
  uri: `${BACKEND_BASE_URL}/graphql`,
  cache: new InMemoryCache(),
});
