import './index.css';
import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
  IonListHeader,
  IonToast,
  IonLoading,
  IonCard,
  IonList,
  IonLabel,
  IonPage,
} from '@ionic/react';
import { TravelItem } from '../../../components/travel-item';
import { PackageFormModal } from '../../../components/package-form-modal';
import { useMutation, useQuery } from '@apollo/client';
import { TravelType } from '../../../gql/type/travel.type';
import { PackageType } from '../../../gql/type/package.type';
import { useParams } from 'react-router';
import { Button } from '../../../components/button';
import {
  GET_PRO_USER_PROFILE_QUERY,
  GetProUserProfileQueryData,
  GetProUserProfileQueryVariables,
} from '../../../gql/query/auth.query.gql';
interface ProProfilePageProps {}
export const ProProfilePage: React.FC<ProProfilePageProps> = (
  props: ProProfilePageProps,
) => {
  const [travelId, setTravelId] = useState<string>('');
  const [userNum, setUserNum] = useState<string>();
  const [load, setLoading] = useState<boolean>(false);
  const [isOpenPackageModal, setIsOpenPackageModal] = useState<boolean>(false);
  const params = useParams<{ num: string }>();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const { data, loading } = useQuery<
    GetProUserProfileQueryData,
    GetProUserProfileQueryVariables
  >(GET_PRO_USER_PROFILE_QUERY, {
    variables: { num: userNum ? userNum : '' },
    skip: !userNum,
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    setUserNum(params.num);
  }, []);

  return (
    <IonPage id='mainContent'>
      <IonLoading
        isOpen={loading || load}
        backdropDismiss={false}
        message='Patientez'
      />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <PackageFormModal
        type={'add'}
        from='guest'
        handleClose={(success) => {
          setIsOpenPackageModal(false);
          if (success) {
            setToastType('primary');
            setToastMessage('Votre demande est envoyée avec succès!');
          }
        }}
        isOpen={isOpenPackageModal}
        travelId={travelId}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonTitle className='ion-text-center'>Profil du GP</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ProProfile_IonContent'>
        {data?.getUserProfile ? (
          <div className='ProProfile_IonContent__header'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <h2
                  style={{ textTransform: 'capitalize' }}
                >{`${data?.getUserProfile.firstname} ${data?.getUserProfile.lastname}`}</h2>
                <p>{`${data?.getUserProfile.phone}`}</p>
              </div>
              {/* <div>
                <Button
                  text={`S'abonner`}
                  color='danger'
                  handleClick={() => {
                    console.log();
                  }}
                ></Button>
              </div> */}
            </div>
          </div>
        ) : (
          <div style={{ padding: 10, textAlign: 'center' }}>
            Profil introuvable
          </div>
        )}

        <IonGrid className='ProProfile_IonGrid'>
          <IonRow className='ProProfile_IonRow'>
            <div style={{ width: '100%' }}>
              {data?.getUserProfile &&
              data?.getUserProfile.lastTravels &&
              data?.getUserProfile.lastTravels.length > 0 ? (
                <>
                  <p style={{ textAlign: 'center', fontWeight: 200 }}>
                    Pour ajouter votre colis, merci de cliquer sur le voyage en
                    question
                  </p>
                  <IonList>
                    {data?.getUserProfile.lastTravels.map((travel) => {
                      return (
                        <IonCard
                          key={travel.id}
                          onClick={() => {
                            setTravelId(travel.id);
                            setIsOpenPackageModal(true);
                          }}
                        >
                          <TravelItem travel={travel} />
                        </IonCard>
                      );
                    })}
                  </IonList>
                </>
              ) : (
                !loading && (
                  <div className='ProProfile__message'>
                    <h2
                      style={{ textAlign: 'center' }}
                    >{`Aucun voyage programmé par le GP pour le moment.
                    `}</h2>
                    {/* <h2
                      style={{ textAlign: 'center' }}
                    >{`Aucun voyage programmé par le GP pour le moment.
                       Abonnez-vous pour être alerter des prochains voyages
                    `}</h2> */}
                    {/* <Button
                      text={`S'abonner`}
                      color='danger'
                      handleClick={() => {
                        console.log();
                      }}
                    ></Button> */}
                  </div>
                )
              )}
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
