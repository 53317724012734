import { Type } from 'class-transformer';
import { Currency } from '../enum/currency.enum';
import { LocationInput } from '../input/location.input';

export class TravelType {
  id!: string;
  num!: string;
  departure!: string;
  distanceBetweenDeparture?: number;
  distanceBetweenArrival?: number;
  @Type(() => LocationInput)
  departureLocation!: LocationInput;
  @Type(() => LocationInput)
  arrivalLocation!: LocationInput;
  arrival!: string;
  pricePerKg!: string;
  currency!: Currency;
  date!: Date;
}
