import { IsNotEmpty, Matches } from 'class-validator';
import { PackageStatus } from '../enum/package-status.enum';
import { PHONE_REGEX } from '../../constant';
import { Expose, Transform } from 'class-transformer';

export class PackageInput {
  @Expose()
  @IsNotEmpty({ message: `Le nom de l'expéditeur est obligatoire` })
  senderFullname!: string;
  @IsNotEmpty({ message: `Le tél de l'expéditeur est obligatoire` })
  @Matches(PHONE_REGEX, {
    message: `Le tél de l'expéditeur est incorrecte`,
  })
  @Expose()
  senderPhone!: string;
  @IsNotEmpty({ message: 'Le nom du destinataire est obligatoire' })
  @Expose()
  receiverFullname!: string;
  @Matches(PHONE_REGEX, {
    message: `Le tél du destinataire est incorrecte`,
  })
  @IsNotEmpty({ message: 'Le tél du destinataire est obligatoire' })
  @Expose()
  receiverPhone!: string;
  @Expose()
  @Transform((value) => {
    return value.value ? parseFloat(value.value) : 0;
  })
  weight!: number;
  @IsNotEmpty({ message: 'Le voyage est obligatoire' })
  @Expose()
  travelId!: string;
  @Expose()
  containerId?: string;
  //@IsNotEmpty({ message: 'Le statut est obligatoire' })
  @Expose()
  status!: PackageStatus;
  @Expose()
  @Transform((value) => {
    if (value.value !== null && value.value !== undefined) {
      if (value.value === 'true') {
        return true;
      } else if (value.value === 'false') {
        return false;
      } else {
        return value.value;
      }
    }
    return false;
  })
  paid!: boolean;
}
