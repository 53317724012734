export const CurrencyOptions: Array<{
  label: string;
  value: string;
  subTitle?: string;
}> = [
  {
    value: 'EURO',
    label: 'Euro',
    subTitle: '€',
  },
  {
    value: 'DOLLAR',
    label: 'Dollar',
    subTitle: '$',
  },
  {
    value: 'CFCA',
    label: 'CFCA',
    subTitle: 'CFCA',
  },
];

export const getCurrency = (value: string) => {
  return CurrencyOptions.find((item) => item.value === value);
};
export const getCurrencySymbol = (value: string) => {
  const res = CurrencyOptions.find((item) => item.value === value);
  return res ? res.subTitle : '';
};
