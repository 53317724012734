export const PackageStatusOptions: Array<{ label: string; value: string }> = [
  {
    value: 'ACCEPTED',
    label: 'Accepter',
  },
  {
    value: 'PENDING',
    label: 'En attente',
  },
  {
    value: 'DELIVERED',
    label: 'Livré au client',
  },
];

export const getPackageStatusLabel = (value: string) => {
  return PackageStatusOptions.find((item) => item.value === value);
};
