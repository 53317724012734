import './dashboard.css';
import React, { useEffect, useMemo, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonLabel,
  IonBadge,
  IonIcon,
  IonButtons,
  IonPage,
  IonLoading,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from '@ionic/react';
import {
  logoWhatsapp,
  notificationsOutline,
  qrCode,
  shareSocial,
} from 'ionicons/icons';
import {
  GET_MY_LAST_TRAVELS_QUERY,
  GetTravelStatsQueryVariables,
  GetMyTravelsQueryVariables,
  GetTravelStatsQueryData,
  GET_TRAVEL_STATS_QUERY,
  GetMyLastTravelsQueryData,
} from '../../gql/query/travel-query.gql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { TravelStatsType } from '../../gql/type/travel-stats.type';
import { Select } from '../../components/select';
import { MomentJs } from '../../lib';
import { Button } from '../../components/button';
import { getCurrencySymbol } from '../../data/currency';
import { PackageFormModal } from '../../components/package-form-modal';
import { useHistory } from 'react-router';
import { TravelFormModal } from '../../components/travel-form-modal';
import QRCode from 'qrcode';
import { useLocation } from 'react-router-dom';
import { useGetProfile } from '../../hooks';

interface DashboardPageProps {}

export const DashboardPage: React.FC<DashboardPageProps> = (
  props: DashboardPageProps,
) => {
  const location = useLocation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [travelStats, setTravelStats] = useState<TravelStatsType>();
  const [travelId, setTravelId] = useState<string>();
  const [userNum, setUserNum] = useState<string>();
  const [isOpenPackageModal, setIsOpenPackageModal] = useState<boolean>(false);
  const [isOpenTravelModal, setIsOpenTravelModal] = useState<boolean>(false);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string>();
  const getProfile = useGetProfile();
  const url = 'https://www.samagp.com/pro-profile';
  const { data, loading, refetch } = useQuery<
    GetMyLastTravelsQueryData,
    GetMyTravelsQueryVariables
  >(GET_MY_LAST_TRAVELS_QUERY);
  const [getTravelStatsQuery] = useLazyQuery<
    GetTravelStatsQueryData,
    GetTravelStatsQueryVariables
  >(GET_TRAVEL_STATS_QUERY, { fetchPolicy: 'network-only' });
  const history = useHistory();
  const getTravelStats = async (id: string) => {
    try {
      setLoading(true);
      const res = await getTravelStatsQuery({ variables: { id } });
      if (res && res.data && res.data.getTravelStats) {
        setTravelStats(res.data.getTravelStats);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getProfile?.data && getProfile.data.getCurrentUser) {
      setUserNum(getProfile.data.getCurrentUser.num);
      QRCode.toDataURL(`${url}/${getProfile.data.getCurrentUser.num}`).then(
        (res) => {
          setQrCodeDataUrl(res);
        },
      );
    }
  }, [getProfile]);
  useEffect(() => {
    if (data && data.getMyLastTravels && data.getMyLastTravels[0]) {
      setTravelId(data.getMyLastTravels[0].id);
    }
  }, [data, location]);
  useEffect(() => {
    refetch();
  }, [location]);
  useMemo(() => {
    if (travelId) {
      getTravelStats(travelId);
    }
  }, [travelId]);
  return (
    <IonPage id='mainContent'>
      <TravelFormModal
        type={'add'}
        handleClose={(success) => {
          setIsOpenTravelModal(false);
          if (success) {
            refetch();
          }
        }}
        isOpen={isOpenTravelModal}
      />
      <PackageFormModal
        type={'add'}
        from='pro'
        handleClose={(success) => {
          setIsOpenPackageModal(false);
          if (success) {
            getTravelStats(travelId || '');
          }
        }}
        isOpen={isOpenPackageModal}
        travelId={travelId || ''}
      />
      <IonLoading
        isOpen={isLoading}
        backdropDismiss={false}
        duration={10000}
        message='Patientez'
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonMenuButton
              slot='start'
              color='secondary'
              className='Ion_Menu_Icon'
            />
          </IonButtons>
          <IonTitle className='ion-text-center'>Accueil</IonTitle>
          <IonLabel
            slot='end'
            className='Dashboard_Notification_content'
            onClick={(e: any) => {
              console.log();
            }}
          >
            <IonBadge
              color='danger'
              className='Dashboard_Notification_NB'
            ></IonBadge>
            <IonIcon
              icon={notificationsOutline}
              className='Dashboard_Notification_Icon'
            />
          </IonLabel>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div>
          <Select
            options={
              data && data.getMyLastTravels
                ? data.getMyLastTravels.map((travel) => {
                    return {
                      label: `${travel.departure} - ${travel.arrival}\n`,
                      value: travel.id,
                      subTitle: MomentJs(travel.date).format('DD/MM/YYYY'),
                    };
                  })
                : []
            }
            cancelText='Annuler'
            label='Sélectionner un voyage'
            mutipleSelect={false}
            okText='Ok'
            value={travelId || ''}
            type='action-sheet'
            handleChange={(value) => {
              setTravelId(value as string);
            }}
          />
        </div>
        {userNum ? (
          <div style={{ display: 'flex' }}>
            <IonCard>
              <IonImg src={qrCodeDataUrl} />
              <div style={{ textAlign: 'center', padding: 10 }}>
                <IonButton
                  size='small'
                  href={`whatsapp://send?text=Bonjour voici le lien pour ajouter votre colis ${url}/${userNum}`}
                >
                  <IonIcon icon={logoWhatsapp} />
                  Partager
                </IonButton>
              </div>
            </IonCard>
            <IonCard>
              <IonCardContent>
                <Button
                  text='Ajouter'
                  color='danger'
                  handleClick={() => {
                    setIsOpenPackageModal(true);
                  }}
                  style={{ width: '100%' }}
                  disabled={!travelId}
                ></Button>
                <Button
                  text='Voir'
                  color='success'
                  handleClick={() => {
                    history.push(`/travel-detail/${travelId}`);
                  }}
                  style={{ width: '100%' }}
                  disabled={!travelId}
                ></Button>
              </IonCardContent>
            </IonCard>
          </div>
        ) : (
          ''
        )}
        {!travelId && !isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <h3>{`Vous n'avez aucun voyage.`}</h3>
            <Button
              text='Nouveau voyage'
              color='success'
              handleClick={() => {
                setIsOpenTravelModal(true);
              }}
            ></Button>
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <IonCard>
            <IonCardContent>
              <div className='Dashboard__IonCard__First'>
                <div>
                  <IonCardTitle>
                    {travelStats ? travelStats.totalPackages : 0}
                  </IonCardTitle>
                  <IonCardSubtitle>Total des colis</IonCardSubtitle>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardContent>
              <div className='Dashboard__IonCard__First'>
                <div>
                  <IonCardTitle>
                    {travelStats ? travelStats.totalWeight : 0} KG
                  </IonCardTitle>
                  <IonCardSubtitle>Poids total</IonCardSubtitle>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
          <IonGrid>
            <IonRow>
              <IonCol size='6'>
                <IonCard>
                  <IonCardContent>
                    <IonCardTitle>
                      {travelStats ? travelStats.totalPaidPackages : 0}
                    </IonCardTitle>
                    <IonCardSubtitle>Total des colis payés</IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size='6'>
                <IonCard>
                  <IonCardContent>
                    <IonCardTitle>
                      {travelStats ? travelStats.totalUnPaidPackages : 0}
                    </IonCardTitle>
                    <IonCardSubtitle>Total des colis impayés</IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='6'>
                <IonCard>
                  <IonCardContent>
                    <IonCardTitle>
                      {travelStats ? travelStats.totalDeliveredPackages : 0}
                    </IonCardTitle>
                    <IonCardSubtitle>Total des colis livrés</IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size='6'>
                <IonCard>
                  <IonCardContent>
                    <IonCardTitle>
                      {travelStats ? travelStats.totalUnDeliveredPackages : 0}
                    </IonCardTitle>
                    <IonCardSubtitle>
                      Total des colis non livrés
                    </IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='6'>
                <IonCard>
                  <IonCardContent>
                    <IonCardTitle>
                      {travelStats ? travelStats.totalPaidPackagesPrice : 0}{' '}
                      {travelStats?.currency
                        ? getCurrencySymbol(travelStats?.currency)
                        : ''}
                    </IonCardTitle>
                    <IonCardSubtitle>Somme totale payée</IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size='6'>
                <IonCard>
                  <IonCardContent>
                    <IonCardTitle>
                      {travelStats ? travelStats.totalUnPaidPackagesPrice : 0}{' '}
                      {travelStats?.currency
                        ? getCurrencySymbol(travelStats?.currency)
                        : ''}
                    </IonCardTitle>
                    <IonCardSubtitle>Somme totale impayée</IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};
