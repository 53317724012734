import './index.css';
import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonLoading,
  IonToast,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonFab,
  IonFabButton,
  IonIcon,
  IonAlert,
  IonCard,
  IonPage,
  IonButtons,
} from '@ionic/react';
import { add, copy, pencil, trash, bagAdd } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { TravelInput } from '../../gql/input/travel.input';
import { TravelItem } from '../../components/travel-item';
import { PackageFormModal } from '../../components/package-form-modal';
import {
  GET_MY_TRAVELS_QUERY,
  GetMyTravelsQueryData,
  GetMyTravelsQueryVariables,
} from '../../gql/query/travel-query.gql';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_TRAVEL_MUTATION,
  DUPLICATE_TRAVEL_MUTATION,
  DeleteTravelMutationData,
  DeleteTravelMutationVariables,
  DuplicateTravelMutationData,
  DuplicateTravelMutationVariables,
} from '../../gql/mutation/travel-mutation.gql';
import { TravelFormModal } from '../../components/travel-form-modal';
import { TravelType } from '../../gql/type/travel.type';
import { Button } from '../../components/button';
import { Menu } from '../../components/menu';
interface TravelPageProps {}
export const TravelPage: React.FC<TravelPageProps> = (
  props: TravelPageProps,
) => {
  const [travel, setTravel] = useState<TravelType>();
  const history = useHistory();
  const [openConfirmAlert, setOpenConfirmAlert] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenTravelModal, setOpenTravelModal] = useState<boolean>(false);
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const { data, loading, refetch } = useQuery<
    GetMyTravelsQueryData,
    GetMyTravelsQueryVariables
  >(GET_MY_TRAVELS_QUERY);
  const [deleteTravelMutation] = useMutation<
    DeleteTravelMutationData,
    DeleteTravelMutationVariables
  >(DELETE_TRAVEL_MUTATION);
  const handleDeleteTravel = () => {
    setLoad(true);
    deleteTravelMutation({
      variables: { id: travel ? travel.id : '' },
    })
      .then((res) => {
        setLoad(false);
        setToastType('success');
        setToastMessage('Voyage supprimé avec succès!');
        refetch();
      })
      .catch((error: Error) => {
        setLoad(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };

  const [duplicateTravelMutation] = useMutation<
    DuplicateTravelMutationData,
    DuplicateTravelMutationVariables
  >(DUPLICATE_TRAVEL_MUTATION);
  const handleDuplicateTravel = (id: string) => {
    setLoad(true);
    duplicateTravelMutation({
      variables: { id },
    })
      .then((res) => {
        setLoad(false);
        setToastType('success');
        setToastMessage('Voyage dupliqué avec succès!');
        refetch();
      })
      .catch((error: Error) => {
        setLoad(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  return (
    <IonPage id='mainContent'>
      <TravelFormModal
        type={travel && travel.id ? 'edit' : 'add'}
        handleClose={(success) => {
          setOpenTravelModal(false);
          if (success) {
            refetch();
          }
        }}
        isOpen={isOpenTravelModal}
        input={travel}
      />
      <PackageFormModal
        type={'add'}
        from='pro'
        handleClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        travelId={travel ? travel.id : ''}
      />
      <IonLoading
        //isOpen={load || loading}
        isOpen={load}
        backdropDismiss={false}
        message='Patientez'
      />
      {!!toastMessage && (
        <IonToast
          isOpen={toastMessage?.length > 0}
          message={toastMessage}
          duration={5000}
          color={toastType}
        />
      )}
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonMenuButton
              slot='start'
              color='secondary'
              className='Ion_Menu_Icon'
            />
          </IonButtons>
          <IonTitle className='ion-text-center'>Mes voyages</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonFab
        vertical='bottom'
        horizontal='end'
        onClick={() => setOpenTravelModal(true)}
        className='Travel__AddBtn'
      >
        <IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonContent>
        <IonAlert
          isOpen={openConfirmAlert}
          onDidDismiss={() => setOpenConfirmAlert(false)}
          subHeader={'Êtes vous sûr ?'}
          buttons={[
            {
              text: 'Oui',
              handler: (value: any) => {
                handleDeleteTravel();
              },
            },
            {
              text: 'Non',
              role: 'cancel',
            },
          ]}
        />
        {!loading && data?.getMyTravels && data.getMyTravels.length === 0 && (
          <div className='Travel__message'>
            <h2
              style={{ textAlign: 'center', padding: 10 }}
            >{`Vous n'avez aucun voyage prevu, merci d'en ajouter`}</h2>
            <Button
              text='Créer'
              color='danger'
              handleClick={() => {
                setTravel(new TravelType());
                setOpenTravelModal(true);
              }}
            ></Button>
          </div>
        )}
        <IonList style={{ marginTop: 40 }}>
          {data?.getMyTravels.map((travel) => {
            return (
              <IonItemSliding key={travel.id}>
                <IonCard className='Travel__Item__Card'>
                  <TravelItem
                    travel={{ ...travel }}
                    onClick={() => {
                      history.push(`/travel-detail/${travel.id}`);
                    }}
                  />
                  <IonItemOptions side='start'>
                    <IonItemOption
                      color='tertiary'
                      expandable
                      onClick={() => {
                        setTravel(travel);
                        setIsOpen(true);
                      }}
                    >
                      <IonIcon icon={bagAdd} />
                    </IonItemOption>
                    <IonItemOption
                      color='primary'
                      expandable
                      onClick={() => {
                        handleDuplicateTravel(travel.id);
                      }}
                    >
                      <IonIcon icon={copy} />
                    </IonItemOption>
                    <IonItemOption
                      color='secondary'
                      expandable
                      onClick={() => {
                        setTravel(travel);
                        setOpenTravelModal(true);
                      }}
                    >
                      <IonIcon icon={pencil} />
                    </IonItemOption>
                    <IonItemOption
                      color='danger'
                      expandable
                      onClick={() => {
                        setTravel(travel);
                        setOpenConfirmAlert(true);
                      }}
                    >
                      <IonIcon icon={trash} />
                    </IonItemOption>
                  </IonItemOptions>
                </IonCard>
              </IonItemSliding>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
