import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import {
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPopover,
  IonRange,
  IonRow,
} from '@ionic/react';
import { add, options } from 'ionicons/icons';
interface SearchFilterProps {
  handleSubmit: (departureRadius: number, arrivalRadius) => void;
  departureRadius: number;
  arrivalRadius: number;
}
export function SearchFilter(props: SearchFilterProps) {
  const { departureRadius, arrivalRadius, handleSubmit } = props;
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [depRadius, setDepRadius] = useState<number>(departureRadius);
  const [arrRadius, setArrRadius] = useState<number>(arrivalRadius);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };
  const onSubmit = () => {
    setPopoverOpen(false);
    handleSubmit(depRadius, arrRadius);
  };
  return (
    <>
      <IonFab
        vertical='bottom'
        horizontal='end'
        style={{ bottom: '-25px' }}
        onClick={openPopover}
      >
        <IonFabButton color={'danger'}>
          <IonIcon icon={options}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        dismissOnSelect={false}
        size='auto'
        className='SearchFilter__Popover'
        mode='ios'
      >
        <IonContent style={{ padding: 20 }}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCol>
                  <IonLabel style={{ fontWeight: 100, paddingLeft: 10 }}>
                    Rayon de départ
                  </IonLabel>
                </IonCol>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonRange
                  pin={true}
                  style={{ marginLeft: 15, marginRight: 15 }}
                  min={5}
                  max={30}
                  snaps={true}
                  ticks={true}
                  value={depRadius}
                  onIonChange={(e) => {
                    setDepRadius(e.target.value.valueOf() as number);
                  }}
                  pinFormatter={(value: number) => `${value}KM`}
                ></IonRange>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCol>
                  <IonLabel style={{ fontWeight: 100, paddingLeft: 10 }}>
                    {`Rayon de d'arrivée`}
                  </IonLabel>
                </IonCol>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonRange
                  pin={true}
                  style={{ marginLeft: 15, marginRight: 15 }}
                  min={5}
                  max={30}
                  snaps={true}
                  ticks={true}
                  value={arrRadius}
                  onIonChange={(e) => {
                    setArrRadius(e.target.value.valueOf() as number);
                  }}
                  pinFormatter={(value: number) => `${value}KM`}
                ></IonRange>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12' style={{ textAlign: 'center' }}>
                <IonButton onClick={() => onSubmit()}>Valider</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPopover>
    </>
  );
}
