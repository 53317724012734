import gql from 'graphql-tag';
export interface SetSeeNotificationQueryVariables {
  id: string;
}
export interface SetSeeNotificationQueryData {
  setSeeNotification: boolean;
}
export const SET_SEE_NOTIFICATION_MUTATION = gql`
  mutation SetSeeNotification($id: String!) {
    setSeeNotification(id: $id)
  }
`;
export interface ClearMyNotificationsQueryVariables {}
export interface clearMyNotificationsQueryData {
  clearMyNotifications: boolean;
}
export const CLEAR_MY_NOTIFICATIONS_MUTATION = gql`
  mutation ClearmyNotifications {
    clearMyNotifications
  }
`;
