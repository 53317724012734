import './index.css';
import React, { useState } from 'react';
import {
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react';
import { Input } from '../input';
import { Button } from '../button';
import { TravelInput } from '../../gql/input/travel.input';
import { MomentJs } from '../../lib';
import { Select } from '../select';
import { Currency } from '../../gql/enum/currency.enum';
import { PointSelectorModal } from '../point-selector-modal';
import { LocationInput } from '../../gql/input/location.input';
interface CreateOrEditTravelProps {
  type: OperationType;
  handleSubmit: (info: TravelInput) => void;
  input: TravelInput;
}
export type OperationType = 'add' | 'edit';
export const CreateOrEditTravel: React.FC<CreateOrEditTravelProps> = (
  props: CreateOrEditTravelProps,
) => {
  const { type, handleSubmit, input } = props;
  const [openSearchPoint, setOpenSearchPoint] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [point, setPoint] = useState<'dep' | 'arr'>('dep');
  const [travel, setTravel] = useState<TravelInput>(
    input || {
      ...new TravelInput(),
      departureLocation: new LocationInput(),
      arrivalLocation: new LocationInput(),
      date: new Date(),
      currency: Currency.EURO,
    },
  );
  const handleInputChange = (key: keyof TravelInput, value: any) => {
    const art = travel;
    art[key] = value;
    setTravel({
      ...art,
    });
  };
  const renderTravelInfo = () => (
    <>
      <PointSelectorModal
        handleClose={() => setOpenSearchPoint(false)}
        isOpen={openSearchPoint}
        message={message}
        handleSelected={(value, coordinates) => {
          setOpenSearchPoint(false);
          if (point === 'dep') {
            setTravel({
              ...travel,
              departure: value,
              departureLocation: { coordinates },
            });
          } else {
            setTravel({
              ...travel,
              arrival: value,
              arrivalLocation: { coordinates },
            });
          }
        }}
      />
      <IonGrid>
        <IonRow>
          <IonCol>
            <Input
              label='Pays de départ *'
              value={travel?.departure}
              handleChange={(value) => {
                //handleInputChange('departure', value);
              }}
              handleFocus={() => {
                setOpenSearchPoint(true);
                setMessage('Pays de départ');
                setPoint('dep');
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label="Pays d'arrivée *"
              value={travel?.arrival}
              handleChange={(value) => {
                //handleInputChange('arrival', value);
              }}
              handleFocus={() => {
                setOpenSearchPoint(true);
                setMessage("Pays de d'arrivée");
                setPoint('arr');
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label='Prix du Kg *'
              type={'number'}
              value={travel?.pricePerKg}
              handleChange={(value) => {
                handleInputChange('pricePerKg', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Select
              type='action-sheet'
              label='La devise *'
              value={travel?.currency}
              options={Object.keys(Currency).map((key) => {
                return {
                  label: key,
                  value: key,
                };
              })}
              cancelText='Annuler'
              okText='OK'
              mutipleSelect={false}
              handleChange={(value) => {
                handleInputChange('currency', value);
              }}
            ></Select>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCol style={{ display: 'flex', justifyContent: 'center' }}>
              <IonItem
                style={{ width: '100%' }}
                className='Create_Or_Edit_Travel_Date_IonItem'
                lines='none'
              >
                <IonLabel style={{ fontSize: 13 }}>Date de départ</IonLabel>
                <IonDatetimeButton datetime='datetime'></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    id='datetime'
                    className='Create_Or_Edit_Travel_Date_IonDatetime'
                    cancelText='Annuler'
                    doneText='Ok'
                    showDefaultButtons={true}
                    presentation='date'
                    value={
                      travel && travel.date
                        ? MomentJs(travel.date).format('YYYY-MM-DDTHH:mm:ss')
                        : MomentJs().format('YYYY-MM-DDTHH:mm:ss')
                    }
                    onIonChange={(e) => {
                      const date = MomentJs(e.target.value).toDate();
                      handleInputChange('date', date);
                    }}
                  ></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
  return (
    <IonContent>
      <IonGrid className='Create_Or_Edit_Travel_Grid'>
        <IonRow className='Create_Or_Edit_Travel_Row'>
          <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
          <IonCol sizeXs='12' sizeSm='10' sizeMd='8'>
            <IonGrid>
              <IonRow>
                <IonCol>{renderTravelInfo()}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='ion-text-center'>
                  <Button
                    handleClick={() => {
                      handleSubmit(travel);
                    }}
                    text={type === 'add' ? `Créer` : 'Mettre à jour'}
                  ></Button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
