import './index.css';
import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonToast,
  IonLoading,
  IonCard,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonFab,
  IonFabButton,
  IonSegment,
  IonSegmentButton,
  IonAlert,
  IonPage,
} from '@ionic/react';
import { TravelItem } from '../../components/travel-item';
import { add, logoWhatsapp, pencil, trash } from 'ionicons/icons';
import { PackageFormModal } from '../../components/package-form-modal';
import {
  GET_MY_PACKAGES_BY_TRAVEL_QUERY,
  GetMyPackagesByTravelQueryData,
  GetMyPackagesByTravelQueryVariables,
} from '../../gql/query/package-query.gql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_TRAVEL_QUERY,
  GetTravelQueryData,
  GetTravelQueryVariables,
} from '../../gql/query/travel-query.gql';
import { TravelType } from '../../gql/type/travel.type';
import { PackageType } from '../../gql/type/package.type';
import { useLocation, useParams } from 'react-router';
import {
  CONTACT_PACKAGE_OWNER_MUTATION,
  ContactPackageOwnerMutationData,
  ContactPackageOwnerMutationVariables,
  DeletePackageMutationVariables,
  PRO_DELETE_PACKAGE_MUTATION,
  ProDeletePackageMutationData,
} from '../../gql/mutation/package-mutation.gql';
import { Button } from '../../components/button';
import { PackageStatus } from '../../gql/enum/package-status.enum';
interface TravelDetailPageProps {}
export const TravelDetailPage: React.FC<TravelDetailPageProps> = (
  props: TravelDetailPageProps,
) => {
  const location = useLocation();
  const [travel, setTravel] = useState<TravelType>(new TravelType());
  const [travelId, setTravelId] = useState<string>();
  const [packag, setPackage] = useState<PackageType>();
  const [packages, setPackages] = useState<PackageType[]>([]);
  const [nbOfOtherPackages, setNbOfOtherPackages] = useState<number>(0);
  const [nbPackageRequested, setNbPackageRequested] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<string>('others');
  const [load, setLoading] = useState<boolean>(false);
  const [isOpenPackageModal, setIsOpenPackageModal] = useState<boolean>(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState<boolean>(false);
  const [openMessageAlert, setOpenMessageAlert] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const { data, loading } = useQuery<
    GetTravelQueryData,
    GetTravelQueryVariables
  >(GET_TRAVEL_QUERY, {
    variables: { id: travelId ? travelId : '' },
    skip: !travelId,
  });
  const [deletePackageMutation] = useMutation<
    ProDeletePackageMutationData,
    DeletePackageMutationVariables
  >(PRO_DELETE_PACKAGE_MUTATION);
  const [contactPackageOwnerMutation] = useMutation<
    ContactPackageOwnerMutationData,
    ContactPackageOwnerMutationVariables
  >(CONTACT_PACKAGE_OWNER_MUTATION);
  const [packageQuery] = useLazyQuery<
    GetMyPackagesByTravelQueryData,
    GetMyPackagesByTravelQueryVariables
  >(GET_MY_PACKAGES_BY_TRAVEL_QUERY, {
    variables: { id: travelId ? travelId : '' },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    setTravelId(params.id);
  }, [params]);
  useEffect(() => {
    if (data?.getTravel) {
      setTravel(data?.getTravel);
    }
  }, [data]);

  useEffect(() => {
    if (travelId) {
      getPackages();
    }
  }, [selectedTab, travelId]);

  const getPackages = () => {
    setLoading(true);
    packageQuery({ variables: { id: travelId ? travelId : '' } })
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          calculedPackages(res.data.getPackagesByTravel);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };
  const handleDeletePackage = () => {
    setLoading(true);
    deletePackageMutation({
      variables: { id: packag ? packag.id : '' },
    })
      .then((res) => {
        setLoading(false);
        setToastType('success');
        setToastMessage('Le colis supprimé avec succès!');
        getPackages();
      })
      .catch((error: Error) => {
        setLoading(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  const sendMesagesToPackageOwner = (message: string) => {
    setLoading(true);
    contactPackageOwnerMutation({
      variables: { input: { message, packageIds: selectedItem } },
    })
      .then((res) => {
        setLoading(false);
        setToastType('success');
        setToastMessage('Message envoyé avec succès!');
        getPackages();
      })
      .catch((error: Error) => {
        setLoading(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };

  const calculedPackages = (data: PackageType[]) => {
    const pending = data.filter((d) => {
      return d.status === PackageStatus.PENDING;
    });
    setNbPackageRequested(pending ? pending.length : 0);
    const others = data.filter((d) => {
      return d.status !== PackageStatus.PENDING;
    });
    setNbOfOtherPackages(others ? others.length : 0);
    if (selectedTab === 'others') {
      setPackages(others);
    } else {
      setPackages(pending);
    }
  };
  return (
    <IonPage id='mainContent'>
      <IonLoading
        isOpen={loading || load}
        backdropDismiss={false}
        message='Patientez'
      />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <IonAlert
        isOpen={openConfirmAlert}
        onDidDismiss={() => setOpenConfirmAlert(false)}
        subHeader={'Êtes vous sûr ?'}
        buttons={[
          {
            text: 'Oui',
            handler: (value: any) => {
              handleDeletePackage();
            },
          },
          {
            text: 'Non',
            role: 'cancel',
          },
        ]}
      />
      <IonAlert
        isOpen={openMessageAlert}
        onDidDismiss={() => setOpenMessageAlert(false)}
        subHeader={'Message'}
        inputs={[
          {
            type: 'textarea',
            name: 'message',
          },
        ]}
        buttons={[
          {
            text: 'Envoyer',
            handler: (value: any) => {
              sendMesagesToPackageOwner(value.message);
            },
          },
          {
            text: 'Annuler',
            role: 'cancel',
          },
        ]}
      />
      <PackageFormModal
        type={packag && packag.id ? 'edit' : 'add'}
        from='pro'
        handleClose={(success) => {
          setIsOpenPackageModal(false);
          if (success) {
            getPackages();
          }
        }}
        isOpen={isOpenPackageModal}
        travelId={travel ? travel.id : ''}
        input={packag}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='packages' />
          </IonButtons>
          <IonTitle className='ion-text-center'>Détail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='TravelDetail_IonContent'>
        <div className='TravelDetail_IonContent__header'>
          <div style={{ width: '100%' }}>
            <IonCard className='TravelDetail__Item'>
              <TravelItem travel={{ ...travel }} />
            </IonCard>
          </div>
          {/* <div style={{ width: '100%' }}>
            <Input placeholder='Rechercher' label='' value='' />
          </div> */}
          <div className='TravelDetail_IonContent__header__segment'>
            <IonSegment
              value={selectedTab}
              className='TravelDetail_IonContent__header__segment__IonSegment'
              onIonChange={(e) => {
                setSelectedTab(e.target.value || 'others');
              }}
            >
              <IonSegmentButton value='others'>
                <IonLabel style={{ display: 'flex' }}>
                  Acceptés{' '}
                  <IonBadge style={{ marginLeft: 10 }} color='danger'>
                    {nbOfOtherPackages}
                  </IonBadge>
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='requested'>
                <IonLabel style={{ display: 'flex' }}>
                  En demandes{' '}
                  <IonBadge style={{ marginLeft: 10 }} color='danger'>
                    {nbPackageRequested}
                  </IonBadge>
                </IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
        </div>
        <IonGrid className='TravelDetail_IonGrid'>
          <IonRow className='TravelDetail_IonRow'>
            <div style={{ width: '100%' }}>
              {packages && packages.length > 0 ? (
                <IonList>
                  {packages.map((pack) => {
                    return (
                      <IonCard className='TravelDetail__Item' key={pack.id}>
                        <IonItemSliding>
                          <IonItem>
                            <IonLabel className='TravelDetail__Item__Package__Item'>
                              <h2 className='TravelDetail__Item__Package__Item__0'>
                                <span>N°:</span> {pack.reference}
                              </h2>
                              <h2 className='TravelDetail__Item__Package__Item__1'>
                                <span>Nom:</span> {pack.receiverFullname}
                              </h2>
                              <p className='TravelDetail__Item__Package__Item__2'>
                                <span>Tel:</span>
                                {pack.receiverPhone}
                              </p>
                              <p className='TravelDetail__Item__Package__Item__2'>
                                <span>Poids:</span>
                                {pack.weight} Kg
                              </p>
                              <p className='TravelDetail__Item__Package__Item__2'>
                                <span>Total à payer:</span>
                                {parseFloat(travel.pricePerKg) *
                                  pack.weight}{' '}
                                {travel.currency}
                              </p>
                              <p className='TravelDetail__Item__Package__Item__2'>
                                <span>Contenaire:</span>
                                {pack.containerName}
                              </p>
                              <p
                                className='TravelDetail__Item__Package__Item__2'
                                style={{ display: 'flex' }}
                              >
                                <span style={{ paddingRight: 10 }}>
                                  Statut:
                                </span>
                                {
                                  <IonBadge
                                    color={
                                      pack.status === PackageStatus.DELIVERED
                                        ? 'primary'
                                        : 'danger'
                                    }
                                  >
                                    {pack.status === PackageStatus.DELIVERED
                                      ? 'Livré'
                                      : 'Non livré'}
                                  </IonBadge>
                                }
                              </p>
                            </IonLabel>
                            <IonBadge color={pack.paid ? 'success' : 'warning'}>
                              {pack.paid ? 'Payé' : 'Impayé'}
                            </IonBadge>
                          </IonItem>
                          <IonItemOptions side='start'>
                            <IonItemOption
                              color='tertiary'
                              expandable
                              onClick={() => {
                                setSelectedItem([pack.id]);
                                setOpenMessageAlert(true);
                              }}
                            >
                              <IonIcon icon={logoWhatsapp} />
                            </IonItemOption>
                            <IonItemOption
                              color='secondary'
                              expandable
                              onClick={() => {
                                setPackage(pack);
                                setIsOpenPackageModal(true);
                              }}
                            >
                              <IonIcon icon={pencil} />
                            </IonItemOption>
                            <IonItemOption
                              color='danger'
                              expandable
                              onClick={() => {
                                setPackage(pack);
                                setOpenConfirmAlert(true);
                              }}
                            >
                              <IonIcon icon={trash} />
                            </IonItemOption>
                          </IonItemOptions>
                        </IonItemSliding>
                      </IonCard>
                    );
                  })}
                </IonList>
              ) : (
                !loading &&
                selectedTab === 'others' && (
                  <div className='TravelDetail__message'>
                    <h2
                      style={{ textAlign: 'center' }}
                    >{`Vous n'avez aucun colis, merci d'en ajouter`}</h2>
                    <Button
                      text='Ajouter'
                      color='danger'
                      handleClick={() => {
                        setPackage(undefined);
                        setIsOpenPackageModal(true);
                      }}
                    ></Button>
                  </div>
                )
              )}
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
      <div>
        {selectedTab === 'others' && (
          <IonFab
            vertical='bottom'
            horizontal='end'
            slot='fixed'
            className='TravelDetail__WhatsappBtn'
            onClick={() => {
              if (packages && packages.length > 0) {
                setOpenMessageAlert(true);
                setSelectedItem(packages.map((item) => item.id));
              }
            }}
          >
            <IonFabButton>
              <IonIcon icon={logoWhatsapp} />
            </IonFabButton>
          </IonFab>
        )}
        <div>
          <IonFab
            vertical='bottom'
            horizontal='end'
            slot='fixed'
            className='TravelDetail__AddBtn'
          >
            <IonFabButton
              color='danger'
              onClick={() => {
                setPackage(undefined);
                setIsOpenPackageModal(true);
              }}
            >
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
        </div>
      </div>
    </IonPage>
  );
};
