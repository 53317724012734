import gql from 'graphql-tag';
import { ProType } from '../type';

export interface CurrentUserQueryVariables {}
export interface CurrentUserQueryData {
  getCurrentUser: ProType;
}
export interface GetProUserProfileQueryVariables {
  num: string;
}
export interface GetProUserProfileQueryData {
  getUserProfile: ProType;
}

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      firstname
      lastname
      phone
      address
      num
    }
  }
`;
export const GET_PRO_USER_PROFILE_QUERY = gql`
  query GetUserProfile($num: String!) {
    getUserProfile(num: $num) {
      id
      email
      firstname
      lastname
      phone
      address
      num
      lastTravels {
        id
        departure
        arrival
        pricePerKg
        currency
        date
      }
    }
  }
`;
