import './register-or-edit-info.css';
import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { Input } from '../input';
import { Button } from '../button';
import { ProInput } from '../../gql';
import { Select } from '../select';
interface RegisterOrEditInfoProps {
  type: OperationType;
  handleSubmit: (info: any) => void;
  input: ProInput;
}
export type OperationType = 'register' | 'edit';
export const RegisterOrEditInfo: React.FC<RegisterOrEditInfoProps> = (
  props: RegisterOrEditInfoProps,
) => {
  const { type, handleSubmit, input } = props;
  const [pro, setPro] = useState<ProInput>(input);
  const handleInputChange = (key: keyof ProInput, value: any) => {
    const art = pro;
    (art as any)[key] = value;
    setPro({
      ...art,
    });
  };
  useEffect(() => {
    setPro(props.input);
  }, [props.input]);
  const renderPersoCoord = () => (
    <>
      <IonGrid>
        <IonRow>
          {type === 'register' && (
            <IonCol>
              <Select
                options={[
                  { label: 'Je suis un GP', value: 'PRO' },
                  { label: 'Je suis un client', value: 'CLIENT' },
                ]}
                cancelText='Annuler'
                label='Type de profil'
                mutipleSelect={false}
                okText='Ok'
                value={pro.role || ''}
                type='action-sheet'
                handleChange={(value) => {
                  handleInputChange('role', value);
                }}
              />
            </IonCol>
          )}
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label='Prénom *'
              value={pro.firstname}
              handleChange={(value) => {
                handleInputChange('firstname', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label='Nom *'
              value={pro.lastname}
              handleChange={(value) => {
                handleInputChange('lastname', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        {type === 'register' && (
          <IonRow>
            <IonCol>
              <Input
                label='Email *'
                type='email'
                value={pro.email}
                handleChange={(value) => {
                  handleInputChange(
                    'email',
                    value?.toLocaleLowerCase()?.trim(),
                  );
                }}
              ></Input>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <Input
              label='Tél *'
              type='tel'
              value={pro.phone}
              handleChange={(value) => {
                handleInputChange('phone', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        {type === 'register' && (
          <IonRow>
            <IonCol>
              <Input
                type='password'
                label='Mot de passe *'
                value={pro.password}
                handleChange={(value) => {
                  handleInputChange('password', value);
                }}
              ></Input>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <Input
              label='Adresse *'
              value={pro.address}
              handleChange={(value) => {
                handleInputChange('address', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
  return (
    <IonContent>
      <IonGrid className='Register_Or_Edit_Grid'>
        <IonRow className='Register_Or_Edit_Row'>
          <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
          <IonCol sizeXs='12' sizeSm='10' sizeMd='8'>
            <IonGrid>
              <IonRow>
                <IonCol>{renderPersoCoord()}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='ion-text-center'>
                  <div className='CGU_cond'>
                    {type === 'register' && (
                      <>
                        En continuant vous acceptez{' '}
                        <IonLabel className='CGU_cond_link'>
                          {`les conditions générales d'utilisation de service`}
                        </IonLabel>
                      </>
                    )}
                  </div>
                  <Button
                    handleClick={() => {
                      handleSubmit(pro);
                    }}
                    text={type === 'register' ? `S'inscrire` : 'Mettre à jour'}
                  ></Button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
