import './menu.css';
import React, { Fragment, ReactNode, useRef } from 'react';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonPage,
} from '@ionic/react';
import { menuController } from '@ionic/core';

import {
  person,
  lockClosed,
  settings,
  logOut,
  home,
  cube,
  airplane,
  search,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { TOKEN_KEY } from '../../constant';

interface MenuProps {}
export const Menu: React.FC<MenuProps> = (props: MenuProps) => {
  const ref: any = useRef(null);
  const close = () => {
    if (ref.current) {
      ref.current.close();
    }
  };
  return (
    <>
      <IonMenu
        side='start'
        contentId='mainContent'
        className='ion-padding'
        ref={ref}
      >
        <IonHeader>
          <IonToolbar color='primary'>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem routerLink='/dashboard' onClick={() => close()}>
              <IonIcon icon={home} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Accueil</IonLabel>
            </IonItem>
            <IonItem routerLink='/search' onClick={() => close()}>
              <IonIcon icon={search} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Recherche</IonLabel>
            </IonItem>
            <IonItem routerLink='/containers' onClick={() => close()}>
              <IonIcon icon={cube} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Contenaires</IonLabel>
            </IonItem>
            <IonItem routerLink='/travels' onClick={() => close()}>
              <IonIcon icon={airplane} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Mes voyages</IonLabel>
            </IonItem>
            <IonItem routerLink='/account' onClick={() => close()}>
              <IonIcon icon={person} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Mon compte</IonLabel>
            </IonItem>
            <IonItem routerLink='/password' onClick={() => close()}>
              <IonIcon icon={lockClosed} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Mot de passe</IonLabel>
            </IonItem>
            {/* <IonItem routerLink='/setting' onClick={() => close()}>
              <IonIcon icon={settings} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Paramètres</IonLabel>
            </IonItem> */}
            <IonItem
              routerLink='/login'
              onClick={() => {
                close();
                localStorage.removeItem(TOKEN_KEY);
              }}
            >
              <IonIcon icon={logOut} className='Menu_List_Icon'></IonIcon>
              <IonLabel>Se déconnecter</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};
