import { IsEmail, IsNotEmpty, Matches, MinLength } from 'class-validator';
import { PHONE_REGEX } from '../../constant';
import { Role } from '../enum/role';
export class ProInput {
  @IsNotEmpty({ message: `Le prénom est obligatoire` })
  firstname!: string;
  @IsNotEmpty({ message: `Le nom est obligatoire` })
  lastname!: string;
  @IsEmail({}, { message: `L'email est invalide` })
  email!: string;
  @Matches(PHONE_REGEX, {
    message: `Le tél est incorrecte`,
  })
  phone!: string;
  @IsNotEmpty({ message: `Le mot de passe est obligatoire` })
  @MinLength(5, { message: 'Le mot de passe doit avoir au moins 5 caractères' })
  password!: string;
  @IsNotEmpty({ message: `L'addresse est obligatoire` })
  address!: string;
  @IsNotEmpty({ message: 'Le type de profil est obligatoire' })
  role?: Role;
}
