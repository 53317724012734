import './index.css';
import React, { useState } from 'react';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonModal,
  IonButtons,
  IonButton,
  IonLoading,
  IonToast,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { TravelInput } from '../../gql/input/travel.input';
import { CreateOrEditTravel } from '../create-or-edit-travel';
import { plainToClass, plainToInstance } from 'class-transformer';
import { useMutation } from '@apollo/client';
import {
  CREATE_TRAVEL_MUTATION,
  CreateTravelMutationData,
  DELETE_TRAVEL_MUTATION,
  DeleteTravelMutationData,
  DeleteTravelMutationVariables,
  TravelMutationVariables,
  UPDATE_TRAVEL_MUTATION,
  UpdateTravelMutationData,
  UpdateTravelMutationVariables,
} from '../../gql/mutation/travel-mutation.gql';
import { TravelType } from '../../gql/type/travel.type';
import { getErrors } from '../../validation/validation-error';
import { validate } from 'class-validator';
interface TravelFormModalProps {
  input?: TravelType;
  type: 'add' | 'edit';
  isOpen: boolean;
  handleClose: (success?: boolean) => void;
}
export const TravelFormModal: React.FC<TravelFormModalProps> = (
  props: TravelFormModalProps,
) => {
  const { input, type, handleClose, isOpen } = props;
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [createTravelMutation] = useMutation<
    CreateTravelMutationData,
    TravelMutationVariables
  >(CREATE_TRAVEL_MUTATION);
  const [updateTravelMutation] = useMutation<
    UpdateTravelMutationData,
    UpdateTravelMutationVariables
  >(UPDATE_TRAVEL_MUTATION);

  const handleCreateTravel = (travel: TravelInput) => {
    setIsLoading(true);
    createTravelMutation({
      variables: { input: plainToClass(TravelInput, travel) },
    })
      .then((res) => {
        setIsLoading(false);
        setToastType('success');
        setToastMessage('Voyage crée avec succès!');
        handleClose(true);
      })
      .catch((error: Error) => {
        setIsLoading(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  const handleUpdateTravel = (travel: TravelInput) => {
    setIsLoading(true);
    if (input?.id) {
      console.log('update', travel);
      updateTravelMutation({
        variables: {
          input: plainToClass(TravelInput, travel),
          id: input?.id,
        },
      })
        .then((res) => {
          setIsLoading(false);
          setToastType('success');
          setToastMessage('Voyage mis à jour avec succès!');
          handleClose(true);
        })
        .catch((error: Error) => {
          setIsLoading(false);
          setToastType('danger');
          setToastMessage(error.message);
        });
    }
  };
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          handleClose();
        }}
      >
        <IonHeader>
          <IonToolbar color='primary'>
            <IonTitle className='ion-text-center' style={{ paddingLeft: 50 }}>
              {type === 'add' ? 'Nouveau voyage' : 'Modifier un voyage'}
            </IonTitle>
            <IonButtons slot='end' color='secondary'>
              <IonButton onClick={() => handleClose()}>
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <>
          <IonLoading
            isOpen={isLoading}
            backdropDismiss={false}
            message='Patientez'
          />
          <IonToast
            isOpen={toastMessage?.length > 0}
            message={toastMessage}
            duration={5000}
            color={toastType}
          />
          <CreateOrEditTravel
            handleSubmit={async (input) => {
              setToastMessage('');
              const t = plainToInstance(TravelInput, input, {
                excludeExtraneousValues: true,
              });
              console.log('travel', t, input);
              const errors = getErrors(await validate(t), true);
              if (errors) {
                setToastMessage(errors as string);
                setToastType('danger');
              } else {
                if (type === 'add') {
                  handleCreateTravel(t);
                } else {
                  handleUpdateTravel(t);
                }
              }
            }}
            input={plainToInstance(TravelInput, input)}
            type={type}
          />
        </>
      </IonModal>
    </>
  );
};
