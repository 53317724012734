import gql from 'graphql-tag';
import { CredentialsInput } from '../input/credentials.input';
import { ProInput } from '../input';
import { ProUpdateInput } from '../input/pro-update.input';

export interface LoginMutationData {
  loginPro: string;
}
export interface LoginMutationVariables {
  credentials: CredentialsInput;
}
export interface RegisterProMutationVariables {
  input: ProInput;
}
export interface RegisterProMutationData {
  registerPro: string;
}
export interface UpdateProProfileMutationVariables {
  input: ProUpdateInput;
}
export interface UpdateProProfileMutationData {
  updateProInfo: boolean;
}
export const LOGIN_MUTATION = gql`
  mutation LoginPro($credentials: CredentialsInput!) {
    loginPro(credentials: $credentials)
  }
`;
export interface ChangeMyPasswordMutationData {
  changeMyPassword: boolean;
}
export interface ChangeMyPasswordMutationVariables {
  oldPassword: string;
  newPassword: string;
}

export const CHANGE_MY_PASSWORD_MUTATION = gql`
  mutation ChangeMyPassword($oldPassword: String!, $newPassword: String!) {
    changeMyPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
export interface RequestPasswordResetMutationData {
  changeMyPassword: boolean;
}
export interface RequestPasswordResetMutationVariables {
  email: string;
}
export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;
export const REGISTER_PRO_MUTATION = gql`
  mutation Register($input: CreateProInput!) {
    register(input: $input)
  }
`;
export const UPDATE_PRO_PROFILE_MUTATION = gql`
  mutation UpdateProInfo($input: UpdateProInput!) {
    updateProInfo(input: $input)
  }
`;
