import { ProInput, ProType } from '../gql';
import { ProUpdateInput } from '../gql/input/pro-update.input';

export const proTypeToProInput = (pro: ProType): ProInput => {
  return {
    password: '',
    firstname: pro.firstname || '',
    lastname: pro.lastname || '',
    address: pro.address || '',
    email: pro.email || '',
    phone: pro.phone || '',
  };
};

export const proInputToProUpdateInput = (pro: ProInput): ProUpdateInput => {
  return {
    firstname: pro.firstname || '',
    lastname: pro.lastname || '',
    address: pro.address || '',
    phone: pro.phone || '',
  };
};
