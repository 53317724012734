import gql from 'graphql-tag';
import {} from '../type';
import { TravelType } from '../type/travel.type';
import { TravelStatsType } from '../type/travel-stats.type';
import { PaginationDto } from '../type/pagination.type';
import { TravelInfoType } from '../type/travel-info.dto';
import { LocationInput } from '../input/location.input';

export interface GetMyTravelsQueryVariables {}
export interface GetMyTravelsQueryData {
  getMyTravels: TravelType[];
}
export interface GetMyLastTravelsQueryData {
  getMyLastTravels: TravelType[];
}

export interface GetTravelStatsQueryVariables {
  id: string;
}
export interface GetTravelStatsQueryData {
  getTravelStats: TravelStatsType;
}
export interface GetTravelsQueryVariables {
  departureRadius: number;
  arrivalRadius: number;
  departureLocation: LocationInput;
  arrivalLocation: LocationInput;
}
export interface GetTravelsQueryData {
  getTravelsByCriteria: TravelInfoType[];
}

export const GET_TRAVEL_STATS_QUERY = gql`
  query GetTravelStats($id: String!) {
    getTravelStats(id: $id) {
      travelId
      departure
      arrival
      date
      currency
      totalPackages
      totalPaidPackages
      totalUnPaidPackages
      totalDeliveredPackages
      totalUnDeliveredPackages
      totalWeight
      totalPaidPackagesPrice
      totalUnPaidPackagesPrice
    }
  }
`;
export const GET_MY_TRAVELS_QUERY = gql`
  query GetMyTravels {
    getMyTravels {
      id
      departure
      arrival
      departureLocation {
        coordinates
      }
      arrivalLocation {
        coordinates
      }
      pricePerKg
      currency
      date
    }
  }
`;
export const GET_TRAVELS_QUERY = gql`
  query GetTravelsByCriteria(
    $departureLocation: LocationInput!
    $arrivalLocation: LocationInput!
    $arrivalRadius: Float!
    $departureRadius: Float!
  ) {
    getTravelsByCriteria(
      departureLocation: $departureLocation
      arrivalLocation: $arrivalLocation
      arrivalRadius: $arrivalRadius
      departureRadius: $departureRadius
    ) {
      id
      departure
      arrival
      pricePerKg
      currency
      distanceBetweenDeparture
      distanceBetweenArrival
      date
      owner {
        num
        firstname
        lastname
        phone
      }
    }
  }
`;
export const GET_MY_LAST_TRAVELS_QUERY = gql`
  query GetMyLastTravels {
    getMyLastTravels {
      id
      departure
      arrival
      pricePerKg
      currency
      date
    }
  }
`;
export interface GetTravelQueryVariables {
  id: string;
}
export interface GetTravelQueryData {
  getTravel: TravelType;
}

export const GET_TRAVEL_QUERY = gql`
  query GetTravel($id: String!) {
    getTravel(id: $id) {
      id
      departure
      arrival
      pricePerKg
      currency
      date
    }
  }
`;
