import './index.css';
import React, { useEffect, useMemo, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
  IonToast,
  IonLoading,
  IonCard,
  IonList,
  IonLabel,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonFab,
  IonFabButton,
  IonIcon,
} from '@ionic/react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router';
import { PointSelectorModal } from '../../components/point-selector-modal';
import { Input } from '../../components/input';
import { PackageFormModal } from '../../components/package-form-modal';
import { TOKEN_KEY } from '../../constant';
import { logIn, options } from 'ionicons/icons';
import {
  GET_TRAVELS_QUERY,
  GetTravelsQueryData,
  GetTravelsQueryVariables,
} from '../../gql/query/travel-query.gql';
import { TravelInfoType } from '../../gql/type/travel-info.dto';
import { TravelItem } from '../../components/travel-item';
import { LocationInput } from '../../gql/input/location.input';
import { SearchFilter } from '../../components/search-filter';
interface SearchPageProps {}
export const SearchPage: React.FC<SearchPageProps> = (
  props: SearchPageProps,
) => {
  const DEFAULT_SIZE = 10;
  const [travelId, setTravelId] = useState<string>('');
  const [departure, setDeparture] = useState<string>('');
  const [departureLocation, setDepartureLocation] = useState<LocationInput>();
  const [arrival, setArrival] = useState<string>('');
  const [arrivalLocation, setArrivalLocation] = useState<LocationInput>();
  const [departureRadius, setDepartureRadius] = useState<number>(10);
  const [arrivalRadius, setArrivalRadius] = useState<number>(10);
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [load, setLoading] = useState<boolean>(false);
  const [isOpenPackageModal, setIsOpenPackageModal] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [openSearchPoint, setOpenSearchPoint] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [travels, setTravels] = useState<TravelInfoType[]>([]);
  const [point, setPoint] = useState<'dep' | 'arr'>('dep');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const [getTravelsQuery] = useLazyQuery<
    GetTravelsQueryData,
    GetTravelsQueryVariables
  >(GET_TRAVELS_QUERY);

  const getTravels = (ev?: any) => {
    if (departureLocation && arrivalLocation) {
      setLoading(true);
      setLoading(true);
      getTravelsQuery({
        variables: {
          departureLocation,
          arrivalLocation,
          departureRadius: departureRadius * 1000,
          arrivalRadius: arrivalRadius * 1000,
        },
      })
        .then((res) => {
          if (res && res.data && res.data.getTravelsByCriteria) {
            setTravels(res.data.getTravelsByCriteria);
          }
        })
        .catch((error) => {
          setToastMessage('Une erreur est survenue, merci de reessayer.');
          setToastType('danger');
        })
        .finally(() => {
          setLoading(false);
          if (ev && ev.target) {
            setTimeout(() => ev.target.complete(), 500);
          }
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsConnected(localStorage.getItem(TOKEN_KEY) ? true : false);
    }, 2000);
  }, [location]);
  useMemo(() => {
    if (departureLocation && arrivalLocation) {
      getTravels();
    }
  }, [departureLocation, arrivalLocation, departureRadius, arrivalRadius]);
  return (
    <IonPage id='mainContent'>
      <IonLoading isOpen={load} backdropDismiss={false} message='Patientez' />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <IonToast
        message="Vous n'êtes pas connecté"
        isOpen={!isConnected}
        color={'danger'}
        buttons={[
          {
            text: 'Se connecter',
            icon: logIn,
            cssClass: 'ToastBtn',
            handler: () => {
              history.push('/login');
            },
          },
        ]}
      ></IonToast>
      <PointSelectorModal
        handleClose={() => setOpenSearchPoint(false)}
        isOpen={openSearchPoint}
        message={message}
        handleSelected={(value, coordinates) => {
          setOpenSearchPoint(false);
          if (point === 'dep') {
            setDeparture(value);
            setDepartureLocation({ coordinates });
          } else {
            setArrival(value);
            setArrivalLocation({ coordinates });
          }
        }}
      />
      <PackageFormModal
        type={'add'}
        from='guest'
        handleClose={(success) => {
          setIsOpenPackageModal(false);
          if (success) {
            setToastType('primary');
            setToastMessage('Votre demande est envoyée avec succès!');
          }
        }}
        isOpen={isOpenPackageModal}
        travelId={travelId}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonTitle className='ion-text-center'>Recherche</IonTitle>
          {isConnected && (
            <IonButtons slot='start'>
              <IonMenuButton
                slot='start'
                color='secondary'
                className='Ion_Menu_Icon'
              />
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className='Search_IonContent'>
        <div className='Search_IonContent__header'>
          <div>
            <Input
              label=''
              placeholder='Pays de départ'
              value={departure}
              handleChange={(value) => {
                //handleInputChange('departure', value);
              }}
              handleFocus={() => {
                setOpenSearchPoint(true);
                setMessage('Pays de départ');
                setPoint('dep');
              }}
            ></Input>
          </div>
          <div>
            <Input
              placeholder="Pays d'arrivée"
              label=''
              value={arrival}
              handleChange={(value) => {
                //handleInputChange('arrival', value);
              }}
              handleFocus={() => {
                setOpenSearchPoint(true);
                setMessage("Pays d'arrivée");
                setPoint('arr');
              }}
            ></Input>
          </div>
          <SearchFilter
            arrivalRadius={arrivalRadius}
            departureRadius={departureRadius}
            handleSubmit={(depRadius, arrRadius) => {
              console.log(arrRadius, depRadius);
              setArrivalRadius(arrRadius);
              setDepartureRadius(depRadius);
            }}
          />
        </div>

        <IonGrid className='Search_IonGrid'>
          {travels.length > 0 && (
            <IonRow className='Search_IonRow'>
              <div style={{ width: '100%' }}>
                <>
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 200,
                      paddingTop: 20,
                    }}
                  >
                    Pour soumettre votre colis, merci de cliquer sur le voyage
                    en question
                  </p>
                </>
              </div>
            </IonRow>
          )}
          <IonRow>
            {!load && travels.length == 0 && departure && arrival && (
              <div className='Search__message'>
                <h2
                  style={{ textAlign: 'center' }}
                >{`Aucun voyage trouvé.`}</h2>
                {/* <h2
                      style={{ textAlign: 'center' }}
                    >{`Aucun voyage programmé par le GP pour le moment.
                       Abonnez-vous pour être alerter des prochains voyages
                    `}</h2> */}
                {/* <Button
                      text={`S'abonner`}
                      color='danger'
                      handleClick={() => {
                        console.log();
                      }}
                    ></Button> */}
              </div>
            )}
          </IonRow>

          <IonRow>
            <IonList style={{ width: '100%' }} role='feed'>
              {travels.map((travel) => {
                return (
                  <IonCard
                    role='article'
                    key={travel.id}
                    onClick={() => {
                      setTravelId(travel.id);
                      setIsOpenPackageModal(true);
                    }}
                  >
                    <div className='Search__IonCard__ProInfo'>
                      <div>{`${travel.owner.firstname} ${travel.owner.lastname}`}</div>
                      <div>{`${travel.owner.phone}`}</div>
                    </div>
                    <TravelItem travel={travel} displayDistance={true} />
                  </IonCard>
                );
              })}
            </IonList>
          </IonRow>
        </IonGrid>
        {/* <IonInfiniteScroll
          onIonInfinite={(ev) => {
            if (size < 100) {
              setSize(size + DEFAULT_SIZE);
              getTravels(ev);
            } else {
              setTimeout(() => ev.target.complete(), 500);
            }
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll> */}
      </IonContent>
    </IonPage>
  );
};
