import './index.css';
import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonLoading,
  IonToast,
  IonList,
  IonItem,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonFab,
  IonFabButton,
  IonIcon,
  IonAlert,
  IonCard,
  IonButtons,
  IonPage,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_CONTAINER_MUTATION,
  ContainerMutationVariables,
  CreateContainerMutationData,
  DELETE_CONTAINER_MUTATION,
  DeleteContainerMutationData,
  DeleteContainerMutationVariables,
  UPDATE_CONTAINER_MUTATION,
  UpdateContainerMutationData,
  UpdateContainerMutationVariables,
} from '../../gql/mutation/container-mutation.gql';
import {
  GET_MY_CONTAINERS_QUERY,
  GetMyContainersQueryData,
  GetMyContainersQueryVariables,
} from '../../gql/query/container-query.gql';
import { ContainerInput } from '../../gql/input/container.input';
import { Button } from '../../components/button';
import { ContainerType } from '../../gql/type/container.type';
import { Menu } from '../../components/menu';
interface ContainerPageProps {}
export const ContainerPage: React.FC<ContainerPageProps> = (
  props: ContainerPageProps,
) => {
  const history = useHistory();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [container, setContainer] = useState<ContainerType>();
  const [openConfirmAlert, setOpenConfirmAlert] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const { data, loading, refetch } = useQuery<
    GetMyContainersQueryData,
    GetMyContainersQueryVariables
  >(GET_MY_CONTAINERS_QUERY);
  const [createContainerMutation] = useMutation<
    CreateContainerMutationData,
    ContainerMutationVariables
  >(CREATE_CONTAINER_MUTATION);
  const [updateContainerMutation] = useMutation<
    UpdateContainerMutationData,
    UpdateContainerMutationVariables
  >(UPDATE_CONTAINER_MUTATION);
  const [deleteContainerMutation] = useMutation<
    DeleteContainerMutationData,
    DeleteContainerMutationVariables
  >(DELETE_CONTAINER_MUTATION);

  const handleCreateContainer = (name: string, description?: string) => {
    setLoad(true);
    createContainerMutation({ variables: { input: { name, description } } })
      .then((res) => {
        setLoad(false);
        setToastType('success');
        setToastMessage('Contenaire crée avec succès!');
        refetch();
      })
      .catch((error: Error) => {
        setLoad(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  const handleUpdateContainer = (name: string, description?: string) => {
    setLoad(true);
    updateContainerMutation({
      variables: {
        input: { name, description },
        id: container ? container.id : '',
      },
    })
      .then((res) => {
        setLoad(false);
        setToastType('success');
        setToastMessage('Contenaire mis à jour avec succès!');
        refetch();
      })
      .catch((error: Error) => {
        setLoad(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  const handleDeleteContainer = () => {
    setLoad(true);
    deleteContainerMutation({
      variables: { id: container ? container.id : '' },
    })
      .then((res) => {
        setLoad(false);
        setToastType('success');
        setToastMessage('Contenaire supprimé avec succès!');
        refetch();
      })
      .catch((error: Error) => {
        setLoad(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  return (
    <IonPage id='mainContent'>
      <IonLoading
        isOpen={load || loading}
        backdropDismiss={false}
        message='Patientez'
      />

      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonMenuButton
              slot='start'
              color='secondary'
              className='Ion_Menu_Icon'
            />
          </IonButtons>
          <IonTitle className='ion-text-center'>Mes contenaires</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonFab
        vertical='bottom'
        horizontal='end'
        onClick={() => {
          setContainer({ id: '', name: '', description: '' });
          setOpenAlert(true);
        }}
        className='Container__AddBtn'
      >
        <IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonContent id='container'>
        <IonAlert
          isOpen={openConfirmAlert}
          onDidDismiss={() => setOpenConfirmAlert(false)}
          subHeader={'Êtes vous sûr ?'}
          buttons={[
            {
              text: 'Oui',
              handler: (value: any) => {
                handleDeleteContainer();
              },
            },
            {
              text: 'Non',
              role: 'cancel',
            },
          ]}
        />
        <IonAlert
          isOpen={openAlert}
          onDidDismiss={(e) => {
            setOpenAlert(false);
          }}
          backdropDismiss={false}
          subHeader={
            container && container.id ? 'Modification' : 'Ajouter un nouveau'
          }
          inputs={[
            {
              name: 'name',
              type: 'text',
              value: container ? container.name : '',
              placeholder: 'Nom du contenaire',
              id: 'Container__Input',
            },
            {
              name: 'description',
              type: 'textarea',
              value: container ? container.description : '',
              placeholder: 'Description',
              id: 'Container__Textarea',
            },
          ]}
          buttons={[
            {
              text: container && container.id ? 'Modifier' : 'Enregistrer',
              handler: (value: ContainerInput) => {
                if (!value.name) {
                  return false;
                }
                if (container && container.id) {
                  handleUpdateContainer(value.name, value.description);
                } else {
                  handleCreateContainer(value.name, value.description);
                  if (value) setContainer({ ...value, id: '' });
                }
              },
            },
            {
              text: 'Annuler',
              role: 'cancel',
            },
          ]}
        />

        {data?.getMyContainers && data?.getMyContainers.length > 0 ? (
          <IonList>
            {data?.getMyContainers.map((container) => {
              return (
                <IonItemSliding key={container.id}>
                  <IonCard className='Container__Item'>
                    <IonItem>
                      <IonLabel>
                        <h2 className='Container___Title'>{container.name}</h2>
                        <p className='Container___SubTitle'>
                          {container.description}
                        </p>
                      </IonLabel>
                    </IonItem>
                    <IonItemOptions side='start'>
                      <IonItemOption
                        color='danger'
                        expandable
                        onClick={() => {
                          setContainer(container);
                          setOpenConfirmAlert(true);
                        }}
                      >
                        Delete
                      </IonItemOption>
                      <IonItemOption
                        color='secondary'
                        expandable
                        onClick={() => {
                          setContainer(container);
                          setOpenAlert(true);
                        }}
                      >
                        Modifier
                      </IonItemOption>
                    </IonItemOptions>
                  </IonCard>
                </IonItemSliding>
              );
            })}
          </IonList>
        ) : (
          !loading && (
            <div className='Container__message'>
              <h2
                style={{ textAlign: 'center' }}
              >{`Vous n'avez aucun contenaire, merci d'en ajouter`}</h2>
              <Button
                text='Créer'
                color='danger'
                handleClick={() => {
                  setContainer({ id: '', name: '', description: '' });
                  setOpenAlert(true);
                }}
              ></Button>
            </div>
          )
        )}
      </IonContent>
    </IonPage>
  );
};
