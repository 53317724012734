import gql from 'graphql-tag';
import { TravelInput } from '../input/travel.input';
export interface TravelMutationVariables {
  input: TravelInput;
}
export interface UpdateTravelMutationVariables {
  input: TravelInput;
  id: string;
}

export interface DeleteTravelMutationVariables {
  id: string;
}
export interface DuplicateTravelMutationVariables {
  id: string;
}
export interface CreateTravelMutationData {
  createTravel: void;
}
export interface UpdateTravelMutationData {
  updateTravel: void;
}

export interface DeleteTravelMutationData {
  deleteTravel: void;
}
export interface DuplicateTravelMutationData {
  duplicateTravel: void;
}
export const CREATE_TRAVEL_MUTATION = gql`
  mutation CreateTravel($input: TravelInput!) {
    createTravel(input: $input)
  }
`;
export const UPDATE_TRAVEL_MUTATION = gql`
  mutation UpdateTravel($input: TravelInput!, $id: String!) {
    updateTravel(input: $input, id: $id)
  }
`;
export const DELETE_TRAVEL_MUTATION = gql`
  mutation DeleteTravel($id: String!) {
    deleteTravel(id: $id)
  }
`;
export const DUPLICATE_TRAVEL_MUTATION = gql`
  mutation DuplicateTravel($id: String!) {
    duplicateTravel(id: $id)
  }
`;
