import './index.css';
import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLoading,
  IonToast,
  IonIcon,
  IonModal,
  IonButtons,
  IonButton,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { PackageInput } from '../../gql/input/package.input';
import { CreateOrEditPackage } from '../create-or-edit-package';
import { useMutation, useQuery } from '@apollo/client';
import {
  GUEST_CREATE_PACKAGE_MUTATION,
  GuestCreatePackageMutationData,
  PRO_CREATE_PACKAGE_MUTATION,
  PRO_UPDATE_PACKAGE_MUTATION,
  PackageMutationVariables,
  ProCreatePackageMutationData,
  ProUpdatePackageMutationData,
  UpdatePackageMutationVariables,
} from '../../gql/mutation/package-mutation.gql';
import { plainToClass } from 'class-transformer';
import { PackageType } from '../../gql/type/package.type';
import { getErrors } from '../../validation/validation-error';
import { validate } from 'class-validator';
import {
  GET_MY_CONTAINERS_QUERY,
  GetMyContainersQueryData,
  GetMyContainersQueryVariables,
} from '../../gql/query/container-query.gql';
import { GuestPackageInput } from '../../gql/input/guest-package.input';
interface PackageFormModalProps {
  input?: PackageType;
  travelId: string;
  type: 'add' | 'edit';
  isOpen: boolean;
  handleClose: (success?: boolean) => void;
  from: 'guest' | 'pro' | 'client';
}
export const PackageFormModal: React.FC<PackageFormModalProps> = (
  props: PackageFormModalProps,
) => {
  const { input, type, handleClose, travelId, from } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [createPackageMutation] = useMutation<
    ProCreatePackageMutationData,
    PackageMutationVariables
  >(PRO_CREATE_PACKAGE_MUTATION);
  const [guestCreatePackageMutation] = useMutation<
    GuestCreatePackageMutationData,
    PackageMutationVariables
  >(GUEST_CREATE_PACKAGE_MUTATION);
  const [updatePackageMutation] = useMutation<
    ProUpdatePackageMutationData,
    UpdatePackageMutationVariables
  >(PRO_UPDATE_PACKAGE_MUTATION);
  const { data, loading, refetch } = useQuery<
    GetMyContainersQueryData,
    GetMyContainersQueryVariables
  >(GET_MY_CONTAINERS_QUERY, { skip: from !== 'pro' });
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  const handleCreatePackage = (packag: PackageInput) => {
    setIsLoading(true);
    const mutation =
      from === 'pro' ? createPackageMutation : guestCreatePackageMutation;

    mutation({
      variables: {
        input:
          from === 'pro'
            ? plainToClass(PackageInput, { ...packag, travelId })
            : plainToClass(
                GuestPackageInput,
                { ...packag, travelId },
                { excludeExtraneousValues: true },
              ),
      },
    })
      .then((res) => {
        setIsLoading(false);
        setToastType('success');
        setToastMessage('Colis ajouté avec succès!');
        handleClose(true);
      })
      .catch((error: Error) => {
        setIsLoading(false);
        setToastType('danger');
        setToastMessage(error.message);
      });
  };
  const handleUpdatePackage = (packag: PackageInput) => {
    setIsLoading(true);
    if (input?.id) {
      updatePackageMutation({
        variables: {
          input: plainToClass(PackageInput, { ...packag, travelId }),
          id: input?.id,
        },
      })
        .then((res) => {
          setIsLoading(false);
          setToastType('success');
          setToastMessage('Colis mis à jour avec succès!');
          handleClose(true);
        })
        .catch((error: Error) => {
          setIsLoading(false);
          setToastType('danger');
          setToastMessage(error.message);
        });
    }
  };
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          handleClose();
        }}
      >
        <IonHeader>
          <IonToolbar color='primary'>
            <IonTitle className='ion-text-center' style={{ paddingLeft: 50 }}>
              {type === 'add' ? 'Nouveau colis' : 'Modifier un colis'}
            </IonTitle>
            <IonButtons slot='end' color='secondary'>
              <IonButton onClick={() => handleClose()}>
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <>
          <CreateOrEditPackage
            from={from}
            containers={
              data && data.getMyContainers ? data.getMyContainers : []
            }
            handleSubmit={async (input) => {
              setToastMessage('');
              const p = plainToClass(
                PackageInput,
                { ...input, travelId },
                {
                  excludeExtraneousValues: true,
                },
              );
              const errors = getErrors(await validate(p), true);
              if (errors) {
                setToastMessage(errors as string);
                setToastType('danger');
              } else {
                if (type === 'add') {
                  handleCreatePackage(p);
                } else {
                  handleUpdatePackage(p);
                }
              }
            }}
            input={input}
            type={type}
          />
        </>
      </IonModal>
      <IonLoading
        //isOpen={load || loading}
        isOpen={loading || isLoading}
        backdropDismiss={false}
        message='Patientez'
      />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
    </>
  );
};
