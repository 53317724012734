import { ValidationError } from 'class-validator';

export const getErrors = (
  validationErrors: ValidationError[],
  justFirstError?: boolean,
) => {
  if (!validationErrors) {
    return [];
  }
  const errors = validationErrors.map((validationError) => {
    if (validationError.constraints) {
      const constraints = Object.keys(validationError.constraints).map(
        (key) => {
          return validationError.constraints
            ? validationError.constraints[key]
            : '';
        },
      );
      return constraints[0];
    }
    return '';
  });
  return justFirstError ? errors[0] : errors && errors.join('\n');
};
