import { Expose } from 'class-transformer';
import { IsNotEmpty, Matches } from 'class-validator';
import { PHONE_REGEX } from '../../constant';

export class ProUpdateInput {
  @Expose()
  @IsNotEmpty({ message: `Le prénom est obligatoire` })
  firstname!: string;
  @Matches(PHONE_REGEX, {
    message: `Le tél est incorrecte`,
  })
  @Expose()
  phone!: string;
  @IsNotEmpty({ message: `Le nom est obligatoire` })
  @Expose()
  lastname!: string;
  @IsNotEmpty({ message: `L'addresse est obligatoire` })
  @Expose()
  address!: string;
}
