import { Expose, Type } from 'class-transformer';
import { IsNotEmpty, IsNotEmptyObject } from 'class-validator';
import { Currency } from '../enum/currency.enum';
import { LocationInput } from './location.input';
export class TravelInput {
  @Expose()
  @IsNotEmpty({ message: 'Le lieu de départ est obligatoire' })
  departure!: string;
  @Type(() => LocationInput)
  @Expose()
  @IsNotEmptyObject(
    { nullable: false },
    { message: 'Impossible de vérifier lieu de départ, reesayer' },
  )
  departureLocation!: LocationInput;
  @IsNotEmpty({ message: `Le lieu d'arrivée est obligatoire` })
  @Expose()
  arrival!: string;
  @Type(() => LocationInput)
  @IsNotEmptyObject(
    { nullable: false },
    { message: `Impossible de vérifier lieu de d'arrivée, reesayer` },
  )
  @Expose()
  arrivalLocation!: LocationInput;
  @IsNotEmpty({ message: 'Le prix du KG est obligatoire' })
  @Expose()
  pricePerKg!: string;
  @IsNotEmpty({ message: 'La date de départ est obligatoire' })
  @Expose()
  date!: Date;
  @IsNotEmpty({ message: 'La devise est obligatoire' })
  @Expose()
  currency!: Currency;
}
