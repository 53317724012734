import './index.css';
import React, { useState } from 'react';
import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import { Input } from '../input';
import { Button } from '../button';
import { PackageInput } from '../../gql/input/package.input';
import { Checkbox } from '../checkbox';
import { PackageType } from '../../gql/type/package.type';
import { Select } from '../select';
import { PackageStatusOptions } from '../../data/package-status';
import { PackageStatus } from '../../gql/enum/package-status.enum';
import { plainToClass } from 'class-transformer';
import { ContainerType } from '../../gql/type/container.type';
interface CreateOrEditPackageProps {
  type: OperationType;
  from: 'guest' | 'pro' | 'client';
  handleSubmit: (info: any) => void;
  input?: PackageType;
  containers: ContainerType[];
}
export type OperationType = 'add' | 'edit';
export const CreateOrEditPackage: React.FC<CreateOrEditPackageProps> = (
  props: CreateOrEditPackageProps,
) => {
  const { type, handleSubmit, input, containers, from } = props;
  const [pack, setPack] = useState<PackageInput>(
    plainToClass(PackageInput, input) || {
      ...new PackageInput(),
      status: PackageStatus.ACCEPTED,
    },
  );
  const handleInputChange = (key: keyof PackageInput, value: any) => {
    const p = Object.assign(pack);
    (p as any)[key] = value;
    setPack({
      ...p,
    });
  };
  const renderPackageInfo = () => (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <Input
              label={`Prénom & Nom de l'expéditeur *`}
              value={pack.senderFullname}
              handleChange={(value) => {
                handleInputChange('senderFullname', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label="Tél de l'expéditeur *"
              value={pack.senderPhone}
              type='tel'
              handleChange={(value) => {
                handleInputChange('senderPhone', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label={`Prénom & Nom du destinataire *`}
              value={pack.receiverFullname}
              handleChange={(value) => {
                handleInputChange('receiverFullname', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label='Tél du destinataire *'
              value={pack.receiverPhone}
              type='tel'
              handleChange={(value) => {
                handleInputChange('receiverPhone', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Input
              label='Poids total (Kg) *'
              value={pack.weight}
              type='number'
              handleChange={(value) => {
                handleInputChange('weight', value);
              }}
            ></Input>
          </IonCol>
        </IonRow>
        {from === 'pro' && (
          <IonRow>
            <IonCol>
              <Select
                label='Contenaire *'
                type='action-sheet'
                value={pack.containerId || ''}
                options={
                  containers
                    ? containers.map((container) => {
                        return {
                          label: container.name,
                          value: container.id,
                        };
                      })
                    : []
                }
                cancelText='Annuler'
                okText='OK'
                mutipleSelect={false}
                handleChange={(value) => {
                  handleInputChange('containerId', value);
                }}
              ></Select>
            </IonCol>
          </IonRow>
        )}
        {from === 'pro' && (
          <IonRow>
            <IonCol>
              <Select
                label='Statut *'
                type='action-sheet'
                value={pack.status}
                options={PackageStatusOptions}
                cancelText='Annuler'
                okText='OK'
                mutipleSelect={false}
                handleChange={(value) => {
                  handleInputChange('status', value);
                }}
              ></Select>
            </IonCol>
          </IonRow>
        )}
        {from === 'pro' && (
          <IonRow>
            <IonCol>
              <div className='Create_Or_Edit_Package__Checkbox'>
                <Checkbox
                  checked={pack.paid}
                  text='Payé par le client ?'
                  handleCheck={(value) => {
                    handleInputChange('paid', value);
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </>
  );
  return (
    <IonContent>
      <IonGrid className='Create_Or_Edit_Package_Grid'>
        <IonRow className='Create_Or_Edit_Package_Row'>
          <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
          <IonCol sizeXs='12' sizeSm='10' sizeMd='8'>
            <IonGrid>
              <IonRow>
                <IonCol>{renderPackageInfo()}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='ion-text-center'>
                  <Button
                    handleClick={() => {
                      handleSubmit(pack);
                    }}
                    text={type === 'add' ? `Créer` : 'Mettre à jour'}
                  ></Button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
