import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import './checkbox.css';
interface CheckboxProps {
  text: string;
  color?: string;
  checked: boolean;
  handleCheck?: (value: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { text, color, checked, handleCheck } = props;
  return (
    <IonItem className='Checkbox_IonItem'>
      <IonCheckbox
        color={color}
        checked={checked}
        onIonChange={(e) => {
          handleCheck && handleCheck(e.detail.checked);
        }}
        className='Checkbox_IonCheckbox'
      ></IonCheckbox>
      <IonLabel className='Checkbox_IonItem__Label'>{text}</IonLabel>
    </IonItem>
  );
};
