import { IonButton } from '@ionic/react';
import React from 'react';
import './button.css';
interface ButtonProps {
  text: string;
  color?: string;
  handleClick?: (event: any) => void;
  style?: any;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { text, color, handleClick, style, disabled } = props;
  return (
    <IonButton
      color={color}
      onClick={handleClick}
      className='Button'
      style={style}
      disabled={disabled}
    >
      {text}
    </IonButton>
  );
};
