import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React, { ReactNode } from 'react';
import './input.css';
interface InputProps {
  label: string;
  value: string | number;
  placeholder?: string;
  color?: string;
  type?: any;
  handleChange?: (value: string | null | undefined) => void;
  handleFocus?: () => void;
  children?: ReactNode;
  position?: 'fixed' | 'stacked' | 'floating' | undefined;
}

export const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    label,
    value,
    placeholder,
    color,
    type,
    handleChange,
    handleFocus,
    children,
    position,
  } = props;
  return (
    <IonItem className='Input_IonItem' lines='none'>
      {label && (
        <IonLabel
          position={position ? position : 'stacked'}
          color={color}
          className='Input_IonLabel'
        >
          {label}
        </IonLabel>
      )}
      {children}
      <IonInput
        value={value}
        placeholder={placeholder}
        onInput={(e) =>
          handleChange && handleChange(e.currentTarget.value + '')
        }
        className='Input'
        type={type}
        onFocus={handleFocus}
      ></IonInput>
    </IonItem>
  );
};
