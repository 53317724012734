import gql from 'graphql-tag';
import {} from '../type';
import { ContainerType } from '../type/container.type';

export interface GetMyContainersQueryVariables {}
export interface GetMyContainersQueryData {
  getMyContainers: ContainerType[];
}

export const GET_MY_CONTAINERS_QUERY = gql`
  query GetMyContainers {
    getMyContainers {
      id
      name
      description
    }
  }
`;
