import './account.css';
import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonMenuButton,
  IonLoading,
  IonToast,
  IonButtons,
  IonPage,
} from '@ionic/react';
import { RegisterOrEditInfo } from '../../components/register-or-edit-info';
import { useHistory } from 'react-router';
import {
  ProType,
  UPDATE_PRO_PROFILE_MUTATION,
  UpdateProProfileMutationData,
  UpdateProProfileMutationVariables,
} from '../../gql';
import { useMutation } from '@apollo/client';
import { proTypeToProInput } from '../../tranformer';
import { useGetProfileLazy } from '../../hooks';
import { proTypeInitializator } from '../../initializator';
import { validate } from 'class-validator';
import { ProUpdateInput } from '../../gql/input/pro-update.input';
import { getErrors } from '../../validation/validation-error';
import { plainToClass } from 'class-transformer';
interface AccountPageProps {}
export const AccountPage: React.FC<AccountPageProps> = (
  props: AccountPageProps,
) => {
  const [pro, setPro] = useState<ProType>(proTypeInitializator);
  const [getProfile, { data, loading, error }] = useGetProfileLazy();
  const history = useHistory();
  const [updateProProfileMutation] = useMutation<
    UpdateProProfileMutationData,
    UpdateProProfileMutationVariables
  >(UPDATE_PRO_PROFILE_MUTATION);
  const [load, setLoad] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');

  useEffect(() => {
    if (error) {
      history.replace('/login');
    }
  }, [error]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (data?.getCurrentUser) {
      const pro = data.getCurrentUser;
      setPro({ ...pro });
      setToastMessage('');
    }
  }, [data]);
  useEffect(() => {
    setLoad(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setToastMessage('');
      setTimeout(() => {
        setToastMessage(error.message);
        setToastType('danger');
      });
    }
  }, [error]);
  const handleInfoSubmit = async (input: ProUpdateInput) => {
    setToastMessage('');
    setLoad(true);
    setTimeout(async () => {
      const validationMessage = await validate(input);
      const errors = getErrors(validationMessage);
      if (!errors) {
        updateProProfileMutation({
          variables: {
            input: plainToClass(
              ProUpdateInput,
              { ...input },
              { excludeExtraneousValues: true },
            ),
          },
        }).then(
          (res) => {
            setLoad(false);
            setToastType('success');
            setToastMessage('Profil mis à jour avec succès!');
          },
          (error: Error) => {
            setLoad(false);
            setToastType('danger');
            setToastMessage(error.message);
          },
        );
      } else {
        setLoad(false);
        setToastType('danger');
        setToastMessage(errors as string);
      }
    });
  };
  return (
    <IonPage id='mainContent'>
      <IonLoading
        isOpen={load || loading}
        backdropDismiss={false}
        message='Patientez'
      />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={5000}
        color={toastType}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            <IonMenuButton
              slot='start'
              color='secondary'
              className='Ion_Menu_Icon'
            />
          </IonButtons>
          <IonTitle className='ion-text-center'>Mon compte</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <RegisterOrEditInfo
          input={proTypeToProInput(pro)}
          handleSubmit={(input) => {
            handleInfoSubmit(input);
          }}
          type='edit'
        ></RegisterOrEditInfo>
      </IonContent>
    </IonPage>
  );
};
