import { useLazyQuery } from '@apollo/client';
import {
  CurrentUserQueryData,
  CurrentUserQueryVariables,
  GET_CURRENT_USER_QUERY,
} from '../../gql/query/auth.query.gql';
export const useGetProfileLazy = () => {
  const getProfile = useLazyQuery<
    CurrentUserQueryData,
    CurrentUserQueryVariables
  >(GET_CURRENT_USER_QUERY, { fetchPolicy: 'network-only' });
  return getProfile;
};
