import gql from 'graphql-tag';
import { PackageInput } from '../input/package.input';
import { GuestPackageInput } from '../input/guest-package.input';
export interface PackageMutationVariables {
  input: PackageInput | GuestPackageInput;
}

export interface UpdatePackageMutationVariables {
  input: PackageInput;
  id: string;
}

export interface DeletePackageMutationVariables {
  id: string;
}
export interface ProCreatePackageMutationData {
  proCreatePackage: void;
}
export interface GuestCreatePackageMutationData {
  guestCreatePackage: void;
}
export interface ProUpdatePackageMutationData {
  proUpdatePackage: void;
}

export interface ProDeletePackageMutationData {
  proDeletePackage: void;
}
export interface ContactPackageOwnerMutationData {
  contactPackageOwner: boolean;
}

export interface ContactPackageOwnerMutationVariables {
  input: { packageIds: string[]; message: string };
}
export const PRO_CREATE_PACKAGE_MUTATION = gql`
  mutation ProCreatePackage($input: ProCreatePackageInput!) {
    proCreatePackage(input: $input)
  }
`;
export const GUEST_CREATE_PACKAGE_MUTATION = gql`
  mutation GuestCreatePackage($input: GuestCreatePackageInput!) {
    guestCreatePackage(input: $input)
  }
`;
export const PRO_UPDATE_PACKAGE_MUTATION = gql`
  mutation ProUpdatePackage($input: ProCreatePackageInput!, $id: String!) {
    proUpdatePackage(input: $input, id: $id)
  }
`;
export const PRO_DELETE_PACKAGE_MUTATION = gql`
  mutation ProDeletePackage($id: String!) {
    proDeletePackage(id: $id)
  }
`;
export const CONTACT_PACKAGE_OWNER_MUTATION = gql`
  mutation ContactPackageOwner($input: ContactPackageOwnerInput!) {
    contactPackageOwner(input: $input)
  }
`;
