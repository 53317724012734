import gql from 'graphql-tag';
import { NotificationType, NotificationWrapperType } from '../type';

export interface GetMyNotificationsSubscriptionData {
  getMyNotifications: NotificationWrapperType;
}
export interface GetMyNotificationsSubscriptionVariables {
  userId: string;
}
export const GET_MY_NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription GetMyNotifications($userId: String!) {
    getMyNotifications(userId: $userId) {
      userId
      notifications {
        id
        title
        message
        data
        type
        see
        since
      }
    }
  }
`;
