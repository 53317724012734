import './register.css';
import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonToast,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { RegisterOrEditInfo } from '../../components/register-or-edit-info';
import { useMutation } from '@apollo/client';
import { proInputInitializator } from '../../initializator';
import {
  CredentialsInput,
  LoginMutationData,
  LoginMutationVariables,
  LOGIN_MUTATION,
  ProInput,
  RegisterProMutationData,
  RegisterProMutationVariables,
  REGISTER_PRO_MUTATION,
} from '../../gql';
import { useHistory } from 'react-router';
import { TOKEN_KEY } from '../../constant';
import { validate } from 'class-validator';
import { plainToClass } from 'class-transformer';
import { getErrors } from '../../validation/validation-error';
import { Role } from '../../gql/enum/role';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RegisterPageProps {}
export const RegisterPage: React.FC<RegisterPageProps> = (
  props: RegisterPageProps,
) => {
  const history = useHistory();
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [login] = useMutation<LoginMutationData, LoginMutationVariables>(
    LOGIN_MUTATION,
  );
  const [registerProMutation] = useMutation<
    RegisterProMutationData,
    RegisterProMutationVariables
  >(REGISTER_PRO_MUTATION);
  const [pro, setPro] = useState<ProInput>({
    ...proInputInitializator,
  });
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const handleInfoSubmit = async (input: ProInput) => {
    setToastMessage('');
    setLoading(true);
    setPro({
      ...input,
    });
    const validationMessage = await validate(plainToClass(ProInput, input));
    const errors = getErrors(validationMessage, true);
    if (errors) {
      console.log(errors);
      setLoading(false);
      setToastType('danger');
      setToastMessage(errors as string);
    } else {
      try {
        await registerProMutation({
          variables: { input },
        });
        setLoading(false);
        setToastType('success');
        setToastMessage(
          'Merci pour votre inscription, veuillez verfier votre email en cliquant sur le lien présent dans votre boite mail',
        );
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      } catch (error: any) {
        setLoading(false);
        setToastType('danger');
        setToastMessage(error.message);
      }
    }
  };
  return (
    <IonPage id='mainContent'>
      <IonLoading
        isOpen={loading}
        backdropDismiss={false}
        message='Patientez'
      />
      <IonToast
        isOpen={toastMessage?.length > 0}
        message={toastMessage}
        duration={toastType === 'danger' ? 5000 : 10000}
        color={toastType}
      />
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start'>
            {step === 0 && <IonBackButton defaultHref='login' />}
          </IonButtons>
          <IonTitle className='ion-text-center'>Inscription</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id='register' className='Register_IonContent'>
        <IonGrid className='Register_IonGrid'>
          <IonRow className='Register_IonRow'>
            <RegisterOrEditInfo
              handleSubmit={(input: ProInput) => {
                handleInfoSubmit(input);
              }}
              input={pro}
              type='register'
            ></RegisterOrEditInfo>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
