import gql from 'graphql-tag';
export interface NotificationQueryVariables {}
export interface NotificationQueryData {
  getNotifications: boolean;
}
export const GET_NOTIFICATIONS_QUERY = gql`
  query GetNotifications {
    getNotifications
  }
`;
