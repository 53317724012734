import './index.css';
import React, { useState } from 'react';
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { airplane, calendarOutline, logoEuro } from 'ionicons/icons';
import { TravelType } from '../../gql/type/travel.type';
import { MomentJs } from '../../lib';
import { TravelInfoType } from '../../gql/type/travel-info.dto';
interface TravelItemProps {
  travel: TravelType;
  onClick?: () => void;
  displayDistance?: boolean;
}
export const TravelItem: React.FC<TravelItemProps> = (
  props: TravelItemProps,
) => {
  const { travel, onClick, displayDistance } = props;
  return (
    <>
      <IonItem
        className='Travel__Item'
        lines='none'
        onClick={() => onClick && onClick()}
      >
        <IonLabel>
          <IonGrid>
            <IonRow>
              <IonCol size='5' className='Travel__Item__Title__Dep'>
                <div>{travel.departure}</div>
                {displayDistance && (
                  <div className='Travel__Item__Title__Dep__Distance'>
                    {travel.distanceBetweenDeparture != undefined &&
                    travel.distanceBetweenDeparture != null
                      ? `${travel.distanceBetweenDeparture.toFixed(2)} KM`
                      : ''}
                  </div>
                )}
              </IonCol>
              <IonCol size='2' className='Travel__Item__Title__Sep'>
                <IonIcon icon={airplane} />
              </IonCol>
              <IonCol size='5' className='Travel__Item__Title__Arr'>
                <div>{travel.arrival}</div>
                {displayDistance && (
                  <div className='Travel__Item__Title__Arr__Distance'>
                    {travel.distanceBetweenArrival != undefined &&
                    travel.distanceBetweenArrival != null
                      ? `${travel.distanceBetweenArrival.toFixed(2)} KM`
                      : ''}
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* <h2 className='Travel__Item__Title'>
            
            
          </h2> */}
          <p className='Travel__Item__SubTitle'>
            <div>
              <span style={{ paddingRight: 10 }}>
                <IonIcon icon={logoEuro} />
              </span>
              <span style={{ paddingRight: 10 }}>Prix du Kg:</span>
              <span> {`${travel.pricePerKg} ${travel.currency}`}</span>
            </div>
            <div>
              <span style={{ paddingRight: 10 }}>
                <IonIcon icon={calendarOutline} />
              </span>
              <span style={{ paddingRight: 10 }}>Date de dép:</span>
              <span> {MomentJs(travel.date).format('DD/MM/YYYY')}</span>
            </div>
          </p>
        </IonLabel>
      </IonItem>
    </>
  );
};
