import { ProInput, ProType } from '../gql';
import { plainToClass } from 'class-transformer';
import { Role } from '../gql/enum/role';

export const proInputInitializator: ProInput = plainToClass(ProInput, {
  firstname: '',
  lastname: '',
  address: '',
  email: '',
  password: '',
  phone: '',
  role: Role.PRO,
});
export const proTypeInitializator: ProType = plainToClass(ProType, {
  ...proInputInitializator,
  id: '',
  num: '',
});
