import { IonSelect, IonItem, IonLabel, IonSelectOption } from '@ionic/react';
import React from 'react';
import { SelectOption } from './select-type';
import './select.css';
interface SelectProps {
  label: string;
  value: string | string[];
  placeholder?: string;
  color?: string;
  okText: string;
  cancelText: string;
  options: SelectOption[];
  mutipleSelect: boolean;
  type?: 'action-sheet' | 'popover' | 'alert';
  handleChange?: (value: string | null | undefined | string[]) => void;
}

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    label,
    value,
    placeholder,
    color,
    okText,
    cancelText,
    options,
    handleChange,
    mutipleSelect,
    type,
  } = props;
  return (
    <IonItem className='Select_IonItem'>
      <IonLabel position='floating' color={color} className='Select_IonLabel'>
        {label}
      </IonLabel>
      <IonSelect
        interface={type}
        value={value}
        placeholder={placeholder}
        onIonChange={(e) => handleChange && handleChange(e.detail.value)}
        className='Select'
        okText={okText}
        cancelText={cancelText}
        multiple={mutipleSelect}
      >
        {options.map((option) => (
          <IonSelectOption value={option.value} key={option.value}>
            <div>{option.label}</div>
            <div>{option.subTitle}</div>
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
