import { IsNotEmpty, Matches } from 'class-validator';
import { PHONE_REGEX } from '../../constant';
import { Expose, Transform } from 'class-transformer';

export class GuestPackageInput {
  @Expose()
  @IsNotEmpty({ message: `Le nom de l'expéditeur est obligatoire` })
  senderFullname!: string;
  @IsNotEmpty({ message: `Le tél de l'expéditeur est obligatoire` })
  @Matches(PHONE_REGEX, {
    message: `Le tél de l'expéditeur est incorrecte`,
  })
  @Expose()
  senderPhone!: string;
  @IsNotEmpty({ message: 'Le nom du destinataire est obligatoire' })
  @Expose()
  receiverFullname!: string;
  @Matches(PHONE_REGEX, {
    message: `Le tél du destinataire est incorrecte`,
  })
  @IsNotEmpty({ message: 'Le tél du destinataire est obligatoire' })
  @Expose()
  receiverPhone!: string;
  @Expose()
  @Transform((value) => {
    return value.value ? parseFloat(value.value) : 0;
  })
  weight!: number;
  @IsNotEmpty({ message: 'Le voyage est obligatoire' })
  @Expose()
  travelId!: string;
}
