import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonLoading, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import 'reflect-metadata';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';
import { LoginPage } from './pages/login';
import { RegisterPage } from './pages/register';
import { Menu } from './components/menu';
import { AccountPage } from './pages/account';
import { PasswordPage } from './pages/password';
import { DashboardPage } from './pages/dashboard';
import { SettingPage } from './pages/setting';
import { ContainerPage } from './pages/container';
import { TravelPage } from './pages/travel';
import { TravelDetailPage } from './pages/travel-detail';
import { setupIonicReact } from '@ionic/react';
import { ProProfilePage } from './pages/guest/pro-profile';
import { SearchPage } from './pages/search';
setupIonicReact({ animated: true, mode: 'ios' });
const Application: React.FC = () => {
  return (
    <>
      <IonApp>
        <IonReactRouter>
          <Menu></Menu>
          <IonRouterOutlet>
            <Route path='/login' component={() => <LoginPage />} exact={true} />
            <Route
              path='/register'
              component={() => <RegisterPage />}
              exact={true}
            />
            <Route
              path='/containers'
              component={() => <ContainerPage />}
              exact={true}
            />
            <Route
              path='/travels'
              component={() => <TravelPage />}
              exact={true}
            />
            <Route
              path='/travel-detail/:id'
              component={() => <TravelDetailPage />}
              exact={true}
            />
            <Route
              path='/pro-profile/:num'
              component={() => <ProProfilePage />}
              exact={true}
            />
            <Route
              path='/password'
              component={() => <PasswordPage />}
              exact={true}
            />
            <Route
              path='/search'
              component={() => <SearchPage />}
              exact={true}
            />
            <Route path='/account' component={() => <AccountPage />} />
            <Route path='/dashboard' component={() => <DashboardPage />} />
            <Route
              path='/'
              render={() => <Redirect to='/search' />}
              exact={true}
            />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </>
  );
};

export default Application;
