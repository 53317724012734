import { TravelType } from './travel.type';

export class ProType {
  id?: string;
  email!: string;
  num!: string;
  firstname!: string;
  phone!: string;
  lastname!: string;
  address!: string;
  lastTravels?: TravelType[];
}
