import './login.css';
import React, { useState } from 'react';
import {
  IonAlert,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import { Input } from '../../components/input';
import { Checkbox } from '../../components/checkbox';
import { Button } from '../../components/button';
import {
  CredentialsInput,
  LoginMutationData,
  LoginMutationVariables,
  LOGIN_MUTATION,
  RequestPasswordResetMutationData,
  RequestPasswordResetMutationVariables,
  REQUEST_PASSWORD_RESET_MUTATION,
} from '../../gql';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { TOKEN_KEY } from '../../constant';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginPageProps {}
export const LoginPage: React.FC<LoginPageProps> = (props: LoginPageProps) => {
  const history = useHistory();
  const [credentials, setCredentials] = useState<CredentialsInput>({
    email: '',
    password: '',
  });
  const [login] = useMutation<LoginMutationData, LoginMutationVariables>(
    LOGIN_MUTATION,
  );
  const [requestPasswordReset] = useMutation<
    RequestPasswordResetMutationData,
    RequestPasswordResetMutationVariables
  >(REQUEST_PASSWORD_RESET_MUTATION);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [toastType, setToastType] = useState<
    'primary' | 'success' | 'secondary' | 'danger' | 'warning'
  >('primary');
  const handleRequestPasswordReset = (e: string) => {
    setLoading(true);
    setToastMessage('');
    const email = e?.toLowerCase()?.trim();
    setTimeout(() => {
      requestPasswordReset({ variables: { email } }).then(
        (res) => {
          setLoading(false);
          setToastType('success');
          setToastMessage('Un mail est envoyé contenant les instructions');
        },
        (error: Error) => {
          setLoading(false);
          setToastType('danger');
          setToastMessage(error.message);
        },
      );
    });
  };
  const handleLoginSubmit = () => {
    setToastMessage('');
    setLoading(true);
    login({ variables: { credentials } }).then(
      (res) => {
        if (res?.data?.loginPro) {
          localStorage.setItem(TOKEN_KEY, res.data?.loginPro);
          setLoading(false);
          setToastType('success');
          setToastMessage('Bienvenue !');
          setTimeout(() => {
            history.push('/search');
          });
        }
      },
      (error: Error) => {
        console.log('error', error);
        setLoading(false);
        setToastType('danger');
        setToastMessage(error.message);
      },
    );
  };
  return (
    <IonPage id='mainContent'>
      <IonContent>
        <IonAlert
          isOpen={openAlert}
          onDidDismiss={() => setOpenAlert(false)}
          subHeader={'Mot de passe oublié'}
          message='Merci de renseigner votre email'
          inputs={[
            {
              name: 'email',
              type: 'email',
              placeholder: 'Email',
            },
          ]}
          buttons={[
            {
              text: 'Envoyer',
              handler: (value: any) => {
                handleRequestPasswordReset(value?.email);
              },
            },
            {
              text: 'Annuler',
              role: 'cancel',
            },
          ]}
        />
        <IonLoading
          isOpen={loading}
          backdropDismiss={true}
          message='Patientez'
        />
        <IonToast
          isOpen={toastMessage?.length > 0}
          message={toastMessage}
          duration={5000}
          color={toastType}
        />
        <IonGrid className='Login_IonGrid'>
          <IonRow className='Login_IonRow_1'>
            <IonCol sizeXs='0' sizeSm='2' sizeMd='3'></IonCol>
            <IonCol sizeXs='12' sizeSm='8' sizeMd='6'>
              <IonGrid>
                <IonRow>
                  <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
                  <IonCol sizeXs='12' sizeSm='10' sizeMd='8'>
                    <IonRow className='ion-text-center'>
                      <IonCol size='12'>
                        {/* <IonImg src='assets/img/logo.png' /> */}
                      </IonCol>
                      <IonCol size='12' className='Login__Title'>
                        SAMA GP
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol sizeXs='0' sizeSm='1' sizeMd='2'></IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol sizeXs='0' sizeSm='2' sizeMd='3'></IonCol>
          </IonRow>
          <IonRow className='Login_IonRow_2'>
            <IonCol size='12'>
              <IonGrid>
                <IonRow>
                  <IonCol sizeXs='0' sizeSm='1' sizeMd='3'></IonCol>
                  <IonCol sizeXs='12' sizeSm='10' sizeMd='6'>
                    <IonCard className='Login_IonCard Ion_Card'>
                      <IonCardContent>
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <Input
                                type='email'
                                label=''
                                placeholder='Email'
                                position='fixed'
                                value={credentials.email}
                                handleChange={(value) => {
                                  setCredentials({
                                    ...credentials,
                                    email: value as string,
                                  });
                                }}
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Mot de passe'
                                label=''
                                position='fixed'
                                value={credentials.password}
                                handleChange={(value) => {
                                  setCredentials({
                                    ...credentials,
                                    password: value as string,
                                  });
                                }}
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Checkbox
                                  checked={showPassword}
                                  text='Afficher le mot de passe'
                                  handleCheck={(value) =>
                                    setShowPassword(value)
                                  }
                                />
                              </div>
                            </IonCol>
                          </IonRow>
                          <IonRow class='ion-text-center'>
                            <IonCol>
                              <Button
                                text='SE CONNECTER'
                                handleClick={() => {
                                  handleLoginSubmit();
                                }}
                                disabled={
                                  !credentials.email || !credentials.password
                                }
                              ></Button>
                            </IonCol>
                          </IonRow>
                          <IonRow class='ion-text-center'>
                            <IonCol>
                              <Button
                                text="S'INSCRIRE"
                                handleClick={() => {
                                  history.push('/register');
                                }}
                              ></Button>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol
                              onClick={() => {
                                setOpenAlert(true);
                              }}
                            >
                              <div style={{ textAlign: 'center' }}>
                                <IonLabel> Mot de passe oublié ?</IonLabel>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol sizeXs='0' sizeSm='1' sizeMd='3'></IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
