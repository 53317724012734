import { useQuery } from '@apollo/client';
import {
  CurrentUserQueryData,
  CurrentUserQueryVariables,
  GET_CURRENT_USER_QUERY,
} from '../../gql/query/auth.query.gql';
export const useGetProfile = () => {
  const { data, loading, error } = useQuery<
    CurrentUserQueryData,
    CurrentUserQueryVariables
  >(GET_CURRENT_USER_QUERY, { fetchPolicy: 'network-only' });

  return { loading, data, error };
};
